
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"

export default class Cgu extends React.Component {
    constructor(props) {
        super(props)
       
    }
    
    render() {
        return (
            <div className="container bg-white py-3 px-3">
                <h6><em>Date de dernière mise à jour <strong>26 nov . 2020 </strong></em></h6>
                <h1 class="text-info text-center">Conditions Générales d'utilisations </h1>
                <div className="text-justify">

                    <br/><br/>
                        <label>	<p className="text-justify">
                        </p><p>Entre la Société Cooperations Office Sarl,  COTONOU AKPAKPA, carrefour Sacré-Cœur Immeuble aux feux tricolores face à l’Eglise, N°RCCM : 15B14509 Cotonou, au Capital</p>
                            <p>Social de 10.125.000FCFA, ayant comme identifiant fiscal unique 3201502308214, représentée par M.Daniel ENIAYEWOU, en qualité de gérant, dûment habilité aux ﬁns des présentes. La société peut être jointe par email en cliquant sur le formulaire de contact accessible via la page d’accueil du site. Ci-après le « Vendeur » ou la « Société ».</p>
                            <p>D’une part, Et la personne physique ou morale procédant à l’achat de produits ou services de la société, Ci-après, « l’Acheteur », ou « le Client » D’autre part, Il a été exposé et convenu ce qui suit :</p>
                            <h3 className="text-info">PREAMBULE</h3>
                            <p>Le Vendeur est éditeur de produits et de services des différentes ventes exclusivement à destination de consommateurs, commercialisés par l’intermédiaire de son site Internet (https://ventepro.online). La liste et le descriptif des biens et services proposés par la Société peuvent être consultés sur les sites susmentionnés.</p>
                            <h3 className="text-info">Article 1 : Objet et dispositions générales</h3>
Les présentes Conditions Générales de Vente déterminent les droits et obligations des parties dans le cadre de la vente en ligne de Produits proposés par le Vendeur.
Les présentes Conditions Générales de Vente (CGV) s’appliquent à toutes les ventes de Produits, effectuées au travers des sites Internet de la Société qui sont partie intégrante du Contrat entre l’Acheteur et le Vendeur. Le Vendeur se réserve la possibilité de modiﬁer les présentes, à tout moment par la publication d’une nouvelle version sur son site Internet. Les CGV applicables alors sont celles étant en vigueur à la date du paiement (ou du premier paiement en cas de paiements multiples) de la commande. Ces CGV sont consultables sur le site Internet de la Société à l'adresse suivante : ventepro.online .  Le Client déclare avoir pris connaissance de l’ensemble des présentes Conditions Générales de Vente, et le cas échéant des Conditions Particulières de Vente liées à un produit ou à un service, et les accepter sans restriction ni réserve. Le Client reconnaît qu’il a bénéﬁcié des conseils et informations nécessaires aﬁn de s’assurer de l’adéquation de l’offre à ses besoins. Le Client déclare être en mesure de contracter légalement en vertu des lois ou valablement représenter la personne physique ou morale pour laquelle il s’engage. Sauf preuve contraire les informations enregistrées par la Société constituent la preuve de l’ensemble des transactions.
<h3 className="text-info">Article 2 : Prix</h3>
Les prix de nos produits sont indiqués en franc CFA et en Dollars toutes en Hors Taxes (HT).
En cas de commande livrée, le client est l'importateur des produits qu’il achète. Le client est seul responsable des déclarations et paiements de tout droit de douane ou autre taxe susceptibles d'être exigibles dans son pays.
<h3 className="text-info">Article 3 : Conclusion du contrat en ligne</h3>
Le Client devra suivre une série d’étapes spéciﬁques à chaque Produit offert par le Vendeur pour pouvoir réaliser sa commande. Toutefois, les étapes décrites ci-après sont systématiques :
<br/>➢ Information sur les caractéristiques essentielles du Produit ;
<br/>➢ Choix du Produit, le cas échéant de ses options et indication des données essentielles du Client (identiﬁcation, adresse…) ;
<br/>➢ Acceptation des présentes Conditions Générales de Vente.
<br/>➢ Vériﬁcation des éléments de la commande et, le cas échéant, correction des erreurs.
<br/>➢ Suivi des instructions pour le paiement, et paiement des produits.
<br/>➢ Livraison des produits. Le Client recevra alors conﬁrmation par courrier électronique du paiement de la commande, ainsi qu’un accusé de réception de la commande la
                                                conﬁrmant. Il recevra un exemplaire .pdf des présentes conditions générales de vente. Pour les
                                                produits livrés, cette livraison se fera à l’adresse indiquée par le Client. )
                                                Aux ﬁns de bonne réalisation de la commande, (et conformément à l’article 1316-1 du Code civil,) le Client s’engage à fournir ses éléments d’identiﬁcation véridiques. Le Vendeur se réserve la possibilité de refuser la commande, par exemple pour toute demande anormale, réalisée de mauvaise foi ou pour tout motif légitime.
<h3 className="text-info">Article 4 : Produits et services</h3>
Les caractéristiques essentielles des biens, des services et leurs prix respectifs sont mis à disposition de l’acheteur sur le site Internet de la société. Le client atteste avoir reçu un détail des frais de livraison ainsi que les modalités de paiement, de livraison et d’exécution du contrat. Le Vendeur s’engage à honorer la commande du Client dans la limite des stocks de Produits disponibles uniquement. A défaut, le Vendeur en informe le Client lors de la validation de la commande. Les parties conviennent que les illustrations ou photos des produits offerts à la vente n’ont pas de valeur contractuelle. La durée de validité de l’offre des Produits ainsi que leurs prix est précisée sur le site Internet de la Société, ainsi que la durée minimale des contrats proposés lorsque ceux-ci portent sur une fourniture continue ou périodique de produits ou services. Sauf conditions particulières, les droits concédés au titre des présentes le sont uniquement à la personne titulaire de l’adresse email communiqué. Conformément aux dispositions légales en matière de conformité et de vices cachés, le Vendeur rembourse ou échange les produits défectueux ou ne correspondant pas à la commande. Le remboursement peut être demandé de la manière suivante : ________ (décrire la procédure de remboursement, et comment le produit doit être retourné, et le remboursement des frais d'expédition le cas échéant).
<h3 className="text-info">Article 5 : Clause de réserve de propriété</h3>
Les produits demeurent la propriété de la Société jusqu’au complet paiement du prix.
<h3 className="text-info">Article 6 : Disponibilité et présentation</h3>
Les commandes seront traitées dans la limite de nos stocks disponibles ou sous réserve des stocks disponibles chez nos fournisseurs. En cas d’indisponibilité d’un article pour une période supérieure à ________ jours ouvrables, vous serez immédiatement prévenu des délais prévisibles de livraison et la commande de cet article pourra être annulée sur simple demande. Le Client pourra alors demander un avoir pour le montant de l’article ou son remboursement.
<h3 className="text-info">Article 7 : Paiement</h3>
Suite à la commande du Produit, la Société émettra un appel de confirmation de la commande au Client, au cours de cet appel sera définir des modalités de paiement et de livraison du produit. Cette confirmation de la réservation restera valide pendant la durée définie lors de l'appel émis par la Société, passer cette durée la commande du Produit n’est plus valide.
<h3 className="text-info">Article 8 : Garanties</h3>
Conformément à la loi, le Vendeur assume deux garanties : de conformité et relative aux vices cachés des produits. Le Vendeur rembourse l'acheteur ou échange les produits apparemment défectueux ou ne correspondant pas à la commande effectuée. La demande de remboursement doit s'effectuer de la manière suivante : un courrier électronique adressée à cooperationsofficesarl@gmail.com  ou appeler le +22967512323. Le Vendeur rappelle que le consommateur : - dispose d'un délai de 2 ans à compter de la délivrance du bien pour agir auprès du Vendeur - qu'il peut choisir entre le remplacement et la réparation du bien sous réserve des conditions prévues par l'article apparemment défectueux ou ne correspondant - qu'il est dispensé d'apporter la preuve l’existence du défaut de conformité du bien durant les six mois suivant la délivrance du bien. - que, sauf biens d’occasion, ce délai sera porté à 24 mois - que le consommateur peut également faire valoir la garantie contre les vices cachés de la chose vendue et, dans cette hypothèse, il peut choisir entre la résolution de la vente ou une réduction du prix de vente.
<h3 className="text-info">Article 9 : Réclamations</h3>
Le cas échéant, l’Acheteur peut présenter toute réclamation en contactant la société au moyen des coordonnées suivantes : un courrier électronique adressée à cooperationsofficesarl@gmail.com  ou appeler le +22967512323.
<h3 className="text-info">Article 10 : Droits de propriété intellectuelle</h3>
Les marques, noms de domaines, produits, logiciels, images, vidéos, textes ou plus généralement toute information objet de droits de propriété intellectuelle sont et restent la propriété exclusive du vendeur. Aucune cession de droits de propriété intellectuelle n’est réalisée au travers des présentes CGV. Toute reproduction totale ou partielle, modiﬁcation ou utilisation de ces biens pour quelque motif que ce soit est strictement interdite.
<h3 className="text-info">Article 11 : Force majeure</h3>
L’exécution des obligations du vendeur au terme des présentes est suspendue en cas de survenance d’un cas fortuit ou de force majeure qui en empêcherait l’exécution. Le vendeur avisera le client de la survenance d’un tel évènement dès que possible.
<h3 className="text-info">Article 12 : Nullité et modiﬁcation du contrat</h3>
Si l’une des stipulations du présent contrat était annulée, cette nullité n’entraînerait pas la nullité des autres stipulations qui demeureront en vigueur entre les parties. Toute modiﬁcation contractuelle n’est valable qu’après un accord écrit et signé des parties.
<h3 className="text-info">Article 13 : Protection des données personnelles</h3>
Conformément au Code numérique, vous disposez des droits d’interrogation, d’accès, de modiﬁcation, d’opposition et de rectiﬁcation sur les données personnelles vous concernant. En adhérant à ces conditions générales de vente, vous consentez à ce que nous collections et utilisions ces données pour la réalisation du présent contrat. En saisissant votre adresse email sur notre site, vous recevrez des emails contenant des informations et des offres promotionnelles concernant des produits édités par la Société et de ses partenaires.

 <p></p>
</label>
		</div>
            </div>

        )
    }
}