
import React, {useState, useEffect, useReducer, useRef} from 'react';
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import { connect } from "react-redux"
import MyHelmet from "../components/Helmet"
import apicall, {apiform} from "../API/APICALL"
import { ToastContainer } from 'react-toastify';
import Swal from "sweetalert2"
import { Link } from "react-router-dom";
import {useKKiaPay} from "kkiapay-react";

function Recharge(props)  {
    const [isLoading, setIsLoading] = useState(false)
    const [montant, setMontant] = useState(0)
    const [key, setKey] = useState("")
    const [mode, setMode] = useState(false)
    const inputAmount = useRef(null);

    const {
        openKkiapayWidget,
        addKkiapayListener,
        removeKkiapayListener
    } = useKKiaPay();

    const open = () => {
       openKkiapayWidget({
            amount: montant,
            fullname : props?.userdata?.name + ' ' + props?.userdata?.prenom,
            api_key: key,
            sandbox: mode,
            email: props?.userdata?.email || "",
            phone: props?.userdata?.numero_rechargement,
        });
       setIsLoading(false)
    }

    const successHandler = (response) =>{
        setIsLoading(false)
        apicall({
            emoney_id: props.emoneyaccount.emoney_id,
            TFac_token_session: props.emoneyaccount.TFac_token_session,
            msisdn: props.userdata.numero_rechargement,
            amount: inputAmount.current.value
        }, 'requestpayment')

            .then((data) => {
                solde();
                    Swal.fire({
                        icon: 'success',
                        title: 'Recharge',
                        text: "Recharge effectuée avec succès !"
                    })
            }).catch((error)=>{})
     }

     const solde = ()=>{
         apicall({ emoney_id: props.emoneyaccount.emoney_id, TFac_token_session: props.emoneyaccount.TFac_token_session }, 'getsolde')
             .then((data) => {
                 if (data.info === "success") {
                     props.dispatch({ type: "UPDATE_SOLDE", value: data.nonformatedsolde })
                 }
             })
     }
    const failureHandler = (error)=> {
        setIsLoading(false)
        Swal.fire({
            icon: 'error',
            title: 'Erreur lors de la recharge',
            text: ""
        })
    }

    const getKKey = ()=>{
        apicall({}, 'settings')
            .then((data) => {
                setKey(data?.kkpay)
                setMode(data?.mode_kkpay)
            }).catch(()=>{setIsLoading(false)})
    }

    useEffect(() => {
        getKKey();
        addKkiapayListener('success',successHandler)
        addKkiapayListener('failed', failureHandler)

        return () => {
            removeKkiapayListener('success',successHandler)
            removeKkiapayListener('failed', failureHandler)
        };

    }, [addKkiapayListener,removeKkiapayListener]);

   const __storeusersession = (userdata) => {
        const action = { type: "SAVE_SESSION", value: userdata }
        props.dispatch(action)
    }

    const __formsubmit = (e) => {
        e.preventDefault()
        
        const formdata = new FormData(e.target);
        formdata.append('user_id', props.userdata.id)
        setIsLoading(true)
        apiform(formdata, "updateuserinfos").then(data => {
            setIsLoading(false)
            if (data.info === "success") {
                __storeusersession(data.data)
                Swal.fire({
                    icon: 'success',
                    title: 'Enregistrer',
                    text: 'Enregistrement effectué avec succes'
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oups',
                    text: data.message
                })
            }
        }).catch(()=>{setIsLoading(false)})
    }

   const updateMont = (e) => {
        if (parseInt(e.target.value)) {
            setMontant(e.target.value)
        } else {
           setMontant(0)
        }
    }
  const _recharge=()=>{
       if (montant>0){
           Swal.fire({
               text: `Confirmez-vous le rechargement de ${montant} CFA par le +229${props.userdata.numero_rechargement} ?`,
               icon: 'info',
               showCancelButton: true,
               confirmButtonColor: '#3085d6',
               cancelButtonColor: '#d33',
               confirmButtonText: 'OUI',
               cancelButtonText: "NON"
           }).then((result) => {
               if (result.isConfirmed){
                   if (props.userdata.emoneyAccount) {
                       setIsLoading(true)
                       open();
                   } else {
                       props.dispatch({ type: "OPEN_MODAL" })
                   }
               }
           }).catch(()=>{setIsLoading(false)})
       }else{
           Swal.fire({
               icon: 'error',
               title: 'Saisissez un montant valide',
               text: ""
           })
       }
       
   }
   const __handleReset = (e) => {
        document.getElementById('formid').reset()
    }


    return (
        <div className='pt-4'>
            <MyHelmet title={"Recharge - " + Constant.APP_DESC} description={Constant.APP_DESC} />
            <h5 className="font-weight-bold mb-3">Recharge de compte par MTN ou MOOV BENIN</h5>

            <div className="form-group">
                <label htmlFor="Montant">Montant de recharge:</label>
                <input disabled={isLoading} ref={inputAmount} type="number" min={1} inputMode="tel" className="form-control" id="Montant" value={montant} onChange={(e)=>{updateMont(e)}}  />
            </div>

            <label htmlFor="numero_rechargement" className="mb-2">Numéro de recharge:</label>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">+229</span>
                </div>
                <input type="text" className="form-control" disabled  defaultValue={props.userdata.numero_rechargement}/>
            </div>
            <Link to={"/portefeuille/parametre"} className="mt-1 mb-2 d-block"> <Icofont icon="settings" /> Configurer le numéro de recharge</Link>
            <button onClick={_recharge} type="button" className="btn btn-info mt-2" disabled={isLoading}>RECHARGER {isLoading && <Icofont icon="spinner" spin="true" />}</button>

            <ToastContainer />
        </div>
    )
}
const mapStateToProps = state => ({ userdata: state.toggleSession.userdata, emoneyaccount: state.toggleSession.emoneyaccount, amountRecharge : state.toggleSession.amountRecharge })
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Recharge);