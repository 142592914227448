import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
import './assets/bootstrap.min.css'
import './assets/bamburgh.css'
import grass4 from './assets/svg/grass4.svg';
import Navbar from './components/Navbar'
import MobileFooter from './components/MobileFooter'
import ProtectedRoute from './components/ProtectedRoute'
import ScrollToTop from './components/ScrollToTop'
import Footer from './components/Footer'
import Home from './screen/Home'
import Portefeuille from './screen/Portefeuille'
import Cgu from './screen/Cgu'
import WheelGame from './screen/WheelGame'
import Cart from './screen/Cart'
import VerifyPin from './screen/VerifyPin'
import Login from './screen/Login'
import Paiement from './screen/Paiement'
import Catalogue from './screen/Catalogue'
import Product from './screen/Product'
import Thanku from './screen/SuccessPay'
import MesFavoris from './screen/MesFavoris'
import GenericNotFound from './screen/GenericNotFound'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";


function App(props) {
  const [isLogin, setisLogin] = useState(true);
  useEffect(() => {
    if (!props.userdata) {
      setisLogin(false)
    } else {
      setisLogin(true)
    }
  });
  return (
    <Router>
      <ScrollToTop>
        <div>
          <div className="App">
            <div className="myconatiner hero-wrapper bg-composed-wrapper">
              <Navbar />
              <div className="maincontainer">
                <Switch
                  atEnter={{ opacity: 0 }}
                  atLeave={{ opacity: 0 }}
                  atActive={{ opacity: 1 }}
                  className="route-wrapper"
                >
                  <Route exact path="/" component={Home} />
                  <Route path="/panier" component={Cart} />
                  <Route path="/wheelgame" component={WheelGame} />
                  <Route path="/catalogue" exact component={Catalogue} />
                  <Route path="/produit/:id/type/:type" component={Product} />
                  <Route path="/terms" component={Cgu} />
                  <Route path="/se-connecter" component={Login} />
                  <Route path="/verifypin" component={VerifyPin} />
                  <Route path="/paiement" component={Paiement} />
                  <Route path="/mes-favoris" exact component={MesFavoris} />
                  <Route path="/thanku" component={Thanku} />
                  <ProtectedRoute path='/portefeuille' islogin={isLogin} component={Portefeuille} />
                  <Route component={GenericNotFound} />
                </Switch>
              </div>
            </div>
          </div>
          <Footer />
          <MobileFooter/>
        </div>
      </ScrollToTop>
    </Router>
  );
}


const mapStateToProps = state => ({ userdata: state.toggleSession.userdata })
const mapDispatchToProps = (dispatch) => {
  return {
    Logoutuser: (action) => { dispatch(action) }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);