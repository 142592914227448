
import React from 'react'
import Icofont from 'react-icofont';
import { getapicall } from "../API/APICALL"
import numberFormat from "../function/numberFormat"
class ResumeArticle extends React.PureComponent{
    
    constructor(props){
        super(props)
        this.state={
            data:null
        }

    }
    
    componentDidMount(){
        if (this.props.item.id_produit){
            this.getproduit()
        }
    }

    getproduit = () => {
        getapicall(`https://ventepro.online/users/function/API/singleproduct.php?id_produit=${this.props.item.id_produit}&typevente=${this.props.item.typevente}`).then(data => {
            if (data.info == "success") {
                this.setState({
                    data: data.data,
                })
            }
        }).catch((error)=>{})
    }
    render(){
        if (this.state.data){
            var etat = { text: "", color: "" }
            switch (this.props.item.etatachatcaut) {
                case "WAITING_CONFIRMATION":
                    etat={text:"En attente",color:"black"}
                    break;
                case "TRANSACTION_ACCEPTED":
                    etat = { text: "Validée", color: "green" }
                    break;
                case "TRANSACTION_CANCELED":
                    etat = { text: "Annulée", color: "red" }
                    break;
            }
            return (
                <div className="br-5 bg-white myshadow mb-2 p-2">
                    <div className="row no-gutters">
                        <div className="col-2">
                            <img src={this.state.data.img1} className="img-fluid"/>
                        </div>
                        <div className="col-10 row no-gutters">
                            <div className="col-9 px-1">
                                <div className="text-uppercase mb-1">N°{this.props.item.transactionRef}</div>
                                <div className="text-gray2">{this.state.data.nomProd}</div>
                            </div>
                            <div className="col-3 text-right" style={{fontSize: "12px" }}>
                                <div style={{ color: etat.color }}>{etat.text}</div>
                            </div>
                            <div className="col-12 row no-gutters pl-1">
                                <div className="col-6" style={{ color: "gray", fontSize: "12px" }}>
                                    <div> Prix Unitaire: <span>{numberFormat(this.props.item.amount)} </span></div>
                                    <div> Qte.: <span>{this.props.item.qte_produit}</span></div>
                                </div>
                                <div className="col-6 text-right">
                                    <div className="text-primary mb-1" style={{ fontSize: this.props.item.type_transaction == "cautionnement"?"11px":"13px" }}> Total: <span>{numberFormat(this.props.item.totalamount)} CFA</span></div>
                                    {this.props.item.type_transaction=="cautionnement" && 
                                    <div>
                                        <div className="text-success mb-1"> Gain: <span>{numberFormat(Math.floor(this.props.item.totalamount * (parseInt(this.state.data.cautionnement_percentage) / 100)))} CFA</span></div>
                                        {this.props.item.etatachatcaut ==="TRANSACTION_ACCEPTED" &&
                                            <div className="text-info mb-1" style={{ fontSize: "11px" }}> {this.props.item.retour_sur_caution_payer === "PAYER" ? "Payer" : "Fin. " + this.props.item.finCautionnement}</div>
                                        }
                                    </div>    
                                    }
                                    <div style={{color:"gray",fontSize:"10px"}}> {this.props.item.date_created}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }else{
            return (<div></div>)
        }
        
    }
}

export default ResumeArticle;