export default {
    APP_NAME:"VENTE PRO",
    HOST:"https://cooperationsoffice.bj",
    APP_DESC:"Plateforme de vente professionnelle",
    APP_KEY:"19GLwi6FzXtkTmPQu85xueXeIZ8f0wcv45aYZSlrZN7UT67kJVldy",
    
    dashbordmenu: [
        { name: "Paiement par tranche", icon: "bullhorn", link: "/paiement-par-tranche" },
        { name: "Mes achats", icon: "bullhorn", link: "/produit-achtetes" },
        { name: "Mes investissements", icon: "bullhorn", link: "/produit-cautionnes" },
        { name: "Activités", icon: "bullhorn", link: "/activite" },
    ],
    speedLink:[
        { name: "Disponible sur PlayStore", icon: "ui-play", bg: "black", link: "https://play.google.com/store/apps/details?id=com.ventepro.office", col: 12,innner:false },
        { name: "Portefeuille", icon: "wallet", bg: "#ff9900", link: "/portefeuille", col: 6,innner:true },
        { name: "Favoris", icon: "heart", bg: "#be0000", link:"/mes-favoris",col:6,innner:true },
        { name: "Contact", icon: "phone", bg: "#00c3da", link: "tel:+22967512323", col: 6,innner:false },
        { name: "Conditions", icon: "page", bg: "#14579e", link: "/terms", col: 6,innner:true },
    ],
    ville:[
        { name: "Cotonou" },
        { name:"Abomey-Calavi"},
        { name:"Porto-Novo"},
        { name:"Parakou"},
        { name:"Djougou"},
        { name:"Bohicon"},
        { name:"Natitingou"},
        { name:"Savé"},
        { name:"Abomey"},
        { name:"Nikki"},
        { name:"Lokossa"},
        { name:"Ouidah"},
        { name:"Dogbo-Tota"},
        { name:"Kandi"},
        { name:"Cové"},
        { name:"Malanville"},
        { name:"Pobé"},
        { name:"Kérou"},
        { name:"Savalou"},
        { name:"Sakété"},
        { name:"Comè"},
        { name:"Banikoara"},
        { name: "Bassila" },
        { name: "Kétou" },
        { name: "Dassa-Zoumè" },
        { name: "Tchaourou" },
        { name: "Allada" },
        { name: "Aplahoué" },
        { name: "Tanguiéta" },
        { name: "N'Dali" },
        { name: "Segbana" },
        { name: "Athiémé" },
        { name: "Grand Popo" },
        { name: "Kouandé" }
    ],

    api:{
        getbaniere:'https://ventepro.online/users/function/API/getban.php'
    }
}