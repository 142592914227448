import React from 'react';
import logo from '../assets/logo.png'


function Loader() {
    return (
        <div
            className="animate-flicker d-flex justify-content-center align-items-center"
        >
            <img src={logo} className="logoimage" />
        </div>
    );
}
export default Loader;