
import React from 'react'
import Article from "../components/Article"
import { connect } from "react-redux";
import Icofont from 'react-icofont';
class MesFavoris extends React.PureComponent {

    render() {
        if (this.props.favoriteAnnonces.length>0){
            return (
                <div className="container">
                    <div className="row no-gutters">
                        {this.props.favoriteAnnonces.map((item, index) => {
                            return (
                                <div className="col-6 col-md-4 col-lg-3 mb-1">
                                    <Article item={item} />
                                </div>
                            )
                        })}

                    </div>
                </div>
            )
        }else{
            return (
                <div className="container pt-5 text-center">
                    <span className="text-danger"><Icofont icon="trash" size="8" /></span>
                    <h5 className="mt-2">Aucun favori !</h5>
                </div>
            )
        }
        
    }
}
const mapStateToProps = state => ({ favoriteAnnonces: state.toggleFavorite.favoriteAnnonces })
export default connect(mapStateToProps)(MesFavoris);