
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import SimpleSlider from "../components/Slider"
import RecentProductSlider from "../components/RecentProductSlider"
import ExpiringProduct from "../components/ExpiringProduct"
import Loader from "../components/Loader"
import {Link} from "react-router-dom";
import { connect } from "react-redux";
import MyHelmet from "../components/Helmet"
import { ToastContainer, toast } from 'react-toastify';
import apicall, { getapicall } from "../API/APICALL"
import optimized_ban from "../assets/helpers/optimized_ban.gif"

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            product:[],
            slide: [],
            expiring:[],
            baniereLoading:false,
            loadingexp:false,
            loadingAll:true,
            loadingNext:false,
            curentPage:2,
            totalpage:0
            
        }
       
    }
    componentDidMount(){
        //this.loadBanniere()
        this.loadExpiring()
        this.loadProduct()
    }
    __loguserout = (e) => {
        e.preventDefault()
        const action = { type: "DELETE_SESSION" }
        this.props.Logoutuser(action)
    }

    loadBanniere = () => {
        this.setState({ baniereLoading: true }, () => {
            getapicall(Constant.api.getbaniere).then(data => {
                this.setState({ baniereLoading: false, slide: data})
            })
        })
    }
    loadExpiring = () => {
        this.setState({ loadingexp: true }, () => {
            getapicall("https://ventepro.online/users/function/API/allproduct.php?page=1&typevente=flash").then(data => {
                this.setState({ loadingexp: false, expiring: data.data })
            })
        })
    }
    loadProduct = () => {
        this.setState({ loadingAll: true }, () => {
            getapicall("https://ventepro.online/users/function/API/allproduct.php?page=" + this.state.curentPage+"&typevente=flash").then(data => {
                this.setState({ loadingAll: false, product: data.data, curentPage: data.page, totalpage: data.totalpage })
            })
        })
    }
    loadNext=()=>{
        if (this.state.curentPage <= this.state.totalpage){
            this.state.curentPage++
            this.setState({ loadingNext: true }, () => {
                getapicall("https://ventepro.online/users/function/API/allproduct.php?page=" + this.state.curentPage + "&typevente=flash").then(data => {
                this.setState({ loadingNext: false, product: [...this.state.product, ...data.data] })
                })
            })
        }
    }
    
    
    
    
    render() {
        return (
            <div className="container">
                <MyHelmet title={Constant.APP_NAME+" - "+Constant.APP_DESC} description={Constant.APP_DESC} />
              <div className="row">
                    
                <div className="col-lg-9">
                    <div className="slidercontainer">
                        <Link to="/wheelgame">
                        <img
                            className="d-block w-100"
                            src={optimized_ban}
                        />
                        </Link>
                    </div>
                        
                        {/* <SimpleSlider slide={this.state.slide}/> */}
                </div>
                    <div className="col-lg-3 d-lg-block d-none">
                        <div className="row mt-3 mt-lg-0">
                            {Constant.speedLink.map((item, index) => {
                                return (
                                    <div className={`col-3 col-lg-${item.col} speedlink text-center mb-2`} key={index}>
                                        {item.innner &&
                                        <Link to={item.link} className="myshadow"  >
                                            <div className=" speedlinkIcon" style={{ backgroundColor: item.bg }}>
                                                <Icofont icon={item.icon} />
                                            </div>
                                            <div className="mt-2 text-black font-weight-bold" style={{fontSize:"12px"}}>
                                                {item.name}
                                            </div>
                                        </Link>
                                        }
                                        {!item.innner &&
                                            <a href={item.link} className="myshadow"  >
                                                <div className=" speedlinkIcon" style={{ backgroundColor: item.bg }}>
                                                    <Icofont icon={item.icon} />
                                                </div>
                                                <div className="mt-2 text-black font-weight-bold" style={{ fontSize: "12px" }}>
                                                    {item.name}
                                                </div>
                                            </a>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>
              </div>
               <div className="my-3">
                    <div className="text-info font-weight-bold">
                        Nouveaux produits
                    </div>
                    <div className="separator" />
                    <div className="py-2">
                        {this.state.loadingexp &&
                            <div className="p-5 text-center text-primary"><Loader/></div>
                        }
                        {!this.state.loadingexp &&
                            <ExpiringProduct product={this.state.expiring} />
                        }
                    </div>
                </div>
                <div className="my-3">
                    <div className="text-info font-weight-bold">
                        Tous les produits
                    </div>
                    <div className="separator" />
                    <div className="py-2">
                        {this.state.loadingAll &&
                            <div className="p-5 text-center text-primary"><Loader /></div>
                        }
                        {!this.state.loadingAll &&
                            <RecentProductSlider product={this.state.product} />
                        }
                        {this.state.curentPage <= this.state.totalpage && 
                        <div className="text-center mt-3 ">
                            <button className="btn btn-primary" disabled={this.state.loadingNext} onClick={()=>{this.loadNext()}}>
                                {this.state.loadingNext ? <Icofont icon="spinner" spin="true" /> :"Charger plus"}
                            </button>
                        </div>
                        }
                    </div>
                </div>
                <ToastContainer />
            </div>

        )
    }
}

const mapStateToProps = state => ({ userdata: state.toggleSession.userdata, cartlist: state.toggleCart.cartList })
const mapDispatchToProps = (dispatch) => {
    return {
        Logoutuser: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);