const initialState = { cartList: [] }

function toggleCart(state = initialState, action) {
    let nextState

    switch (action.type) {
        case "REMOVE_FROM_CART":
            var productIndex = state.cartList.findIndex((item) => { return item.id === action.value.id })
            if (productIndex !== -1) {
                //retire l'objet de l'annonce au state
                nextState = {
                    ...state,
                    cartList: state.cartList.filter((item, index) => { return index !== productIndex })
                }
            }
            return nextState || state
            break;
        case "EMPTY_CART" :
            nextState = {
                ...state,
                cartList: []
            }
            return nextState || state
            break;
        case "ADD_TO_CART":
            var productIndex = state.cartList.findIndex((item) => { return item.id === action.value.id })
            if (productIndex !== -1) {
                state.cartList[productIndex].quantite = action.value.quantite
                nextState = {
                    ...state,
                    cartList: state.cartList
                }
            } else {
                nextState = {
                    ...state,
                    cartList: [...state.cartList, action.value]
                }
            }
            return nextState || state
            break;
        case "INCREMENT_QUANTITE":
            var productIndex = state.cartList.findIndex((item) => { return item.id === action.value.id })
            if (productIndex !== -1) {
                let clone = Object.assign({}, state.cartList[productIndex]);
                clone.quantite++
                state.cartList[productIndex]=clone
                nextState = {
                    ...state,
                    cartList: [
                        ...state.cartList
                    ]
                }
            }
            return nextState || state
            break;
        case "DECREMENT_QUANTITE":
            var productIndex = state.cartList.findIndex((item) => { return item.id === action.value.id })
            if (productIndex !== -1) {
                state.cartList[productIndex].quantite = state.cartList[productIndex].quantite > 1 ? state.cartList[productIndex].quantite-1:1
                nextState = {
                    ...state,
                    cartList: [
                        ...state.cartList
                    ]
                }
            }
            return nextState || state
            break;
        default:
            return state
            break;
    }
}

export default toggleCart