
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"

export default class Paiement extends React.Component {
    constructor(props) {
        super(props)
       
    }
    
    render() {
        return (
            <div className="container">
              Paiement
            </div>

        )
    }
}