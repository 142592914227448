
const initialState = { userdata: false, emoneyaccount: false, showModal:false, amountRecharge : true }

function toggleSession(state = initialState, action) {
    let nextState

    switch (action.type) {
        case "SAVE_SESSION":
            nextState = {
                ...state,
                userdata: action.value
            }
            return nextState || state
            break;
        case "SAVE_EMONEY_TOKEN":
            nextState = {
                ...state,
                emoneyaccount: action.value
            }
            return nextState || state
            break;
        case "UPDATE_SOLDE":
            nextState = {
                ...state,
                userdata: { ...state.userdata, emoneySolde: action.value}
            }
            return nextState || state
            break;
        case "PENDING_SOLDE":
            nextState = {
                ...state,
                amountRecharge:  action.value
            }
            return nextState || state
            break;
        case "OPEN_MODAL":
            nextState = {
                ...state,
                showModal: true
            }
            return nextState || state
            break;
        case "CLOSE_MODAL":
            nextState = {
                ...state,
                showModal: false
            }
            return nextState || state
            break;
        case "DELETE_SESSION":
            nextState = {
                ...state,
                userdata: false,
                emoneyaccount:false,
                showModal:false
            }
            return nextState || state
            break;
        default:
            return state
            break;
    }
}

export default toggleSession