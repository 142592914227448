import React, { Component } from "react";
import Constant from "../constants/constant"
import Icofont from 'react-icofont';
import { Link } from "react-router-dom";
const f1=[
    { name: "Livraison", icon: "vehicle-delivery-van", description: "Livraison gratuite au Bénin pour toute commande supérieur à 250.000 CFA" },
    { name: "Support 24/7", icon: "ui-head-phone", description:"Nous sommes joignables au téléphone et par email 24h/24 et 7j/7."},
    { name: "Paiement facile", icon: "pay", description:"Paiement par mobile money (Moov et Flooz), par carte visa et à la livraison"},
    { name: "Produits certifiés", icon: "ssl-security", description: "Nous ne vendons que les produits certifiés par des laboratoires légaux" },
]
export default class Footer extends Component {
    render() {
        
        return (
            <div className="footer">
                <div className="container-fluid f-2">
                     <div className="container">
                        <div className="row">
                            <div className="col-md-4 p-0">
                                <div className="media p-3">
                                    <Icofont icon="google-map" size="3" />
                                    <div className="media-body ml-2">
                                        <h4>Localisation</h4>
                                        <p>Cotonou Akpakpa, Carrefour Sacré-Coeur</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 p-0">
                                <div className="media p-3">
                                    <Icofont icon="touch" size="3" />
                                    <div className="media-body ml-2">
                                        <h4>Réseaux sociaux</h4>
                                        <ul>
                                            <li><a href="https://fr-fr.facebook.com/cooperations.office" className="text-white">Facebook</a></li>
                                            <li><a href="https://wa.me/+22967512323" className="text-white">Whatsapp</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 p-0">
                                <div className="media p-3">
                                    <Icofont icon="link" size="3" />
                                    <div className="media-body ml-2">
                                        <h4>Liens</h4>
                                        <ul>
                                            <li><a href="https://cooperationsoffice.bj" className="text-white">Cooperations Office</a></li>
                                            <li><a href="/cgu" className="text-white">Conditions générales</a></li>
                                            <li><a href="tel:+22967512323" className="text-white">+229 67512323</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                </div>
                <div className="container-fluid f-3 py-2 text-center">
                    Copyright © {new Date().getFullYear()} - {Constant.APP_NAME} <small><em>designed by <a className="text-white" href="https://wa.me/22967526513">eSoluce</a></em></small>
                </div>
            </div>
        );
    }
}