//store/reducers/favoriteReducers.js

const initialState = {favoriteAnnonces:[]}

function toggleFavorite (state = initialState, action){
    let nextState

    switch (action.type) {
        case "TOGGLE_FAVORITE":
            const favoriteAnnoncesIndex = state.favoriteAnnonces.findIndex((item) => { return item.id === action.value.id})
            if (favoriteAnnoncesIndex !== -1){
                //retire l'objet de l'annonce au state
                nextState={
                    ...state,
                    favoriteAnnonces: state.favoriteAnnonces.filter((item, index) => { return index !== favoriteAnnoncesIndex})
                }
            }else{
                // ajouter l'objet de l'annonce au film
                nextState = {
                    ...state,
                    favoriteAnnonces: [...state.favoriteAnnonces, action.value]
                }
            }
            return nextState || state
            break;
    
        default:
            return state
            break;
    }
}

export default toggleFavorite