
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import { connect } from "react-redux";
import apicall from "../API/APICALL"
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer, toast } from 'react-toastify';
import MyHelmet from "../components/Helmet"
import '../assets/spinner.css'
import Swal from "sweetalert2"
import Countdown from 'react-countdown';
const sections=[
    {
        primaryTxt:'5%',
        secondaryOfferText:'Réduction',
        style: {
            transform: "rotate(30deg)",
            WebkitTransform: "rotate(30deg)",
            MozTransform: "rotate(30deg)",
            OTransform: "rotate(30deg)",
            msTransform: "rotate(30deg)",
            borderColor: "#32a0d1 transparent",
        },
    },
    {
        primaryTxt: '?',
        secondaryOfferText: 'Surprise',
        style: {
            transform: "rotate(90deg)",
            WebkitTransform: "rotate(90deg)",
            MozTransform: "rotate(90deg)",
            OTransform: "rotate(90deg)",
            msTransform: "rotate(90deg)",
            borderColor: "#f18500 transparent",
        }
    },
    {
        primaryTxt: '10%',
        secondaryOfferText: 'Réduction',
        style: {
            transform: "rotate(150deg)",
            WebkitTransform: "rotate(150deg)",
            MozTransform: "rotate(150deg)",
            OTransform: "rotate(150deg)",
            msTransform: "rotate(150deg)",
            borderColor: "#d93a36 transparent",
        }
    },
    {
        primaryTxt: '?',
        secondaryOfferText: 'Surprise',
        style: {
            transform: "rotate(210deg)",
            WebkitTransform: "rotate(210deg)",
            MozTransform: "rotate(210deg)",
            OTransform: "rotate(210deg)",
            msTransform: "rotate(210deg)",
            borderColor: "#32a0d1 transparent",
        }
    },
    {
        primaryTxt: '15%',
        secondaryOfferText: 'Réduction',
        style: {
            transform: "rotate(270deg)",
            WebkitTransform: "rotate(270deg)",
            MozTransform: "rotate(270deg)",
            OTransform: "rotate(270deg)",
            msTransform: "rotate(270deg)",
            borderColor: "#f18500 transparent",
        }
    },
    {
        primaryTxt: '?',
        secondaryOfferText: 'Surprise',
        style: {
            transform: "rotate(330deg)",
            WebkitTransform: "rotate(330deg)",
            MozTransform: "rotate(330deg)",
            OTransform: "rotate(330deg)",
            msTransform: "rotate(330deg)",
            borderColor: "#d93a36 transparent",
        }
    },
]
class WheelGame extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            isRotating: false,
            isLoading:false,
            data:null,
            hasPlayed: false,
            winnerSection:null,
            isLoadingIner:false,
            timer:10000
        }
       this.endattimer=10000
    }

    componentDidMount(){
        if (this.props.tempWinner) {
            this.setState({ 
                hasPlayed: true,
                winnerSection: this.props.tempWinner,
                timer:Date.now() + this.endattimer
            })
        }
        this.initWheel(false)
    }
    initWheel=(rejouer=true)=>{
        if (rejouer){
            this.props.doaction({ type: "SAVE_CODE", value: null })
            this.setState({
                winnerSection: null,
                hasPlayed: false
            })
        }
        this.setState(
            {
                rotateDeg: 0,
                degState: [
                    {
                        state: '5%',
                        degMax: 60
                    },
                    {
                        state: '?',
                        degMax: 120
                    },
                    {
                        state: '10%',
                        degMax: 180
                    },
                    {
                        state: '?',
                        degMax: 240
                    },
                    {
                        state: '15%',
                        degMax: 300
                    },
                    {
                        state: '?',
                        degMax: 360
                    }
                ]
            }, () => { this.getData()}
        )
    }
    
    play=()=>{
        if (!this.state.isRotating){
            let x = 1024
            let y = 19999
            let degToAdd = +1 * Math.floor(Math.random() * (x - y) + y)
            
            let oldDegState = this.state.degState
            let winner = null
            let newDegState = oldDegState
            let newRotateDeg = this.state.rotateDeg + degToAdd
            this.state.degState.map((item, index) => {
                let degMax = (item.degMax + degToAdd) % 360
                newDegState[index].degMax = degMax + degToAdd
                if (degMax - 60 <= 0 && degMax >= 0) {
                    
                    winner = this.state.data[newDegState[index].state]
                }
            })
            
            this.setState({
                isRotating: true,
                rotateDeg: newRotateDeg,
                degState: newDegState,
            }, () => {
                setTimeout(() => {
                    this.setState({
                        isRotating: false,
                        hasPlayed:true,
                        winnerSection: winner,
                        timer:Date.now() + this.endattimer
                    })
                }, 6500)
            })
        }
    }
    
    getData=()=>{
        this.setState({ isLoading: true }, () => {
            apicall({}, 'getwheelgamebonus')
            .then(data => {
                this.setState({ isLoading: false })
                if (data.info === "success") {
                    this.setState({
                        data: data.data
                    })
                } else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
        })
    }
     tempSave=()=>{
        this.props.doaction({ type: "SAVE_CODE", value: this.state.winnerSection })
    }

    reserveBonus=()=>{
        if (this.props.userdata){
            if (this.props.emoneyaccount) {
                if (this.props.userdata.emoneyAccount) {
                    this.setState({ isLoadingIner: true }, () => {
                        apicall({ emoney_id: this.props.emoneyaccount.emoney_id, TFac_token_session: this.props.emoneyaccount.TFac_token_session, codepromo: this.state.winnerSection.code, bonuspercentage: this.state.winnerSection.percentage }, 'reserverwheelgame')
                            .then((data) => {
                                this.setState({ isLoadingIner: false })
                                if (data.info === "success") {
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Félicitations !',
                                        text: 'Réservation éffectuée pour 30min; Consultez et appliquez votre remise dans le panier'
                                    })
                                    this.initWheel()
                                } else {
                                    this.tempSave()
                                    if (data.message === "INVALID_TFac_token_session" || data.message === "EXPIRED_SESSION_TFAC") {
                                        let timerInterval
                                        Swal.fire({
                                            title: 'Important',
                                            html: 'Entrez votre pin avant de pouruivre',
                                            timer: 3000,
                                            timerProgressBar: true,
                                            didOpen: () => {
                                                Swal.showLoading()
                                                timerInterval = setInterval(() => {
                                                    const content = Swal.getContent()
                                                    if (content) {
                                                        const b = content.querySelector('b')
                                                        if (b) {
                                                            b.textContent = Swal.getTimerLeft()
                                                        }
                                                    }
                                                }, 100)
                                            },
                                            willClose: () => {
                                                clearInterval(timerInterval)
                                            }
                                        }).then((result) => {
                                            /* Read more about handling dismissals below */
                                            if (result.dismiss === Swal.DismissReason.timer) {
                                                this.props.history.push('/portefeuille?callback=wheelgame')
                                            }
                                        })
                                        
                                    } else if (data.message==="INSUFISIANT_FUND"){
                                        Swal.fire({
                                            title: 'Fonds insuffisant',
                                            text: "Voulez-vous recharger votre compte?",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'OUI', 
                                            cancelButtonText: "NON"
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                this.props.history.push('/portefeuille/recharge')
                                            }
                                        })
                                    } else {
                                        toast.error(data.message, {
                                            position: toast.POSITION.TOP_RIGHT
                                        });
                                    }

                                }
                            })
                    })
                } else {
                    this.tempSave()
                    this.props.history.push('/portefeuille?callback=wheelgame')
                }
            } else {
                this.tempSave()
                this.props.history.push('/portefeuille?callback=wheelgame')
            }
        }else{
            this.tempSave()
            this.props.history.push('/se-connecter?callback=wheelgame')
        }
    }
    renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span/>
        } else {
            let timer=''
            if (minutes > 0) {
                timer = timer + " " +minutes+ "min"
            }
            return <span>{timer} {seconds} Sec</span>
        }
    }
    render() {
        return (
            <div className="container-fluid wheelBg">
                <MyHelmet title={"Jouez et Gagnez pleins de surprises - " + Constant.APP_DESC} description={Constant.APP_DESC} />
                <div className="mainwrapper">
                    <div className="toast"></div>
                    <div className="captchaPage" hidden={!this.state.hasPlayed}>
                        <div className="captchaModal">
                            <img id="questionImg" src={require('../assets/undraw_happy_birthday_s72n.svg').default} className='w-25'/>
                                <div className="text2a" id="questionTitle">{this.state.winnerSection?this.state.winnerSection.percentage:0}% de réduction sur votre prochain achat</div>
                                <div className="questionText" id="question">{this.state.winnerSection?this.state.winnerSection.code:0}</div>
                                <div className="btn-group btn-group-sm">
                                <button disabled={this.state.isLoadingIner} type="button" className="btn btn-info" onClick={this.reserveBonus}>{this.state.isLoadingIner ? <Icofont icon="spinner" spin="true" /> :'Réserver avec 100F'} </button>
                                    <button disabled={this.state.isLoadingIner} type="button" onClick={this.initWheel} className="btn btn-danger">Rejouer</button>
                                </div>
                                {
                                    this.state.hasPlayed &&
                                    <div className="text-center text-danger font-weight-bold">
                                        <Countdown
                                            date={this.state.timer}
                                            renderer={this.renderer}
                                            onComplete={() => {this.initWheel() }}
                                        />
                                    </div>
                                }
                                
			            </div>
                        </div>
                        <div className="container">
                            <div className="head1" id="title" style={{fontFamilly: "lemon", fontSize: "62px"}}>Jouez et Gagnez</div>
                            <div className="text1" id="text1">Des <span className="text-warning font-weight-bold text-uppercase">Réductions surprises</span> sur vos prochains Achats via VentePro.online</div>
                            {!this.state.isLoading && this.state.data &&
                            <div align="center" className="wheel">
                                <div className="wrapper">
                                    <div className="wheel">
                                    <div id="inner-wheel" style={{transform:`rotate(${this.state.rotateDeg}deg)`}}>
                                        {sections.map((item,index)=>{
                                            return(
                                                <div
                                                    key={index}
                                                    style={item.style}
                                                    className="sec"
                                                >
                                                    <div className="primaryOfferText">{item.primaryTxt}</div>
                                                    <div className="secondaryOfferText">{item.secondaryOfferText}</div>
                                                </div>
                                            )
                                        })}
                                        
                                    </div>
                                    <div id="spin" onClick={this.play} className={this.state.isRotating?"":"animateSpinButton"}>
                                            <div id="inner-spin">Jouez</div>
                                        </div>
                                        <div className="shine"></div>
                                        <div className="glow">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {this.state.isLoading &&
                                <div className="text-center text-white">
                                    <Icofont icon="spinner" spin="true" size={5} />
                                </div>
                            }
                        </div>
                    </div>
                <ToastContainer />
            </div>

        )
    }
}

const mapStateToProps = state => ({ userdata: state.toggleSession.userdata, tempWinner: state.saveWinnerFunction.tempWinner, emoneyaccount: state.toggleSession.emoneyaccount, showModal: state.toggleSession.showModal })
const mapDispatchToProps = (dispatch) => {
    return {
        doaction: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WheelGame);