
const initialState = { pinid: false }

function savePin(state = initialState, action) {
    let nextState

    switch (action.type) {
        case "SAVE_PIN":
            nextState = {
                ...state,
                pinid: action.value
            }
            return nextState || state
            break;
        case "DELETE_PIN":
            nextState = {
                ...state,
                pinid: false
            }
            return nextState || state
            break;
        default:
            return state
            break;
    }
}

export default savePin