
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import {connect} from "react-redux"
import MyHelmet from "../components/Helmet"
import apicall from "../API/APICALL"
import numberFormat from '../function/numberFormat'
import CatalogMagic from '../components/ShimmerLoader'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Swal from "sweetalert2"
class TrancheDetail extends React.Component {
    constructor(props) {
        super(props)
       this.state={
           isLoadingIner:false,
           data: null,
           isPaying:false
       }
        this.resetElement=''
    }
    componentDidMount(){
        this.loadData()
    }
    loadData=()=>{
        if (this.props.emoneyaccount) {
            if (this.props.userdata.emoneyAccount) {
                this.setState({ isLoadingIner: true }, () => {
                    apicall({ emoney_id: this.props.emoneyaccount.emoney_id, TFac_token_session: this.props.emoneyaccount.TFac_token_session, id_payement_en_tranche: this.props.match.params.id }, 'tranchedetail')
                        .then((data) => {
                            this.setState({ isLoadingIner: false })
                            if (data.info == "success") {
                                this.setState({data:data.data})
                            } else {
                                if (data.message === "INVALID_TFac_token_session" || data.message ==="EXPIRED_SESSION_TFAC") {
                                    this.props.dispatch({ type: "OPEN_MODAL" })
                                } else {
                                    toast.error(data.message, {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }

                            }
                        })
                })
            } else {
                this.props.dispatch({ type: "OPEN_MODAL" })
            }
        } else {
            this.props.dispatch({ type: "OPEN_MODAL" })
        }
    }
    pay=()=>{
        if (window.confirm("Confirmez le paiement de " + this.state.data.prochain_acompte_a_payer+" CFA")){
            if (this.props.userdata.emoneySolde > this.state.data.prochain_acompte_a_payer){
                this.setState({isPaying:true},()=>{
                    apicall({ emoney_id: this.props.emoneyaccount.emoney_id, TFac_token_session: this.props.emoneyaccount.TFac_token_session, id_payement_en_tranche: this.props.match.params.id }, 'payeracompte')
                        .then((data) => {
                            if (data.info == "success") {
                                this.setState({data:data.data},()=>{
                                    this.props.getSolde()
                                })
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oups',
                                    text: data.message
                                })
                            }
                            this.setState({ isPaying: false })
                        })
                })
            }else{
                Swal.fire({
                    title: 'Fond insuffisant',
                    text: "Rechargez votre compte.",
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OUI',
                })
            }
        }
    }
    render() {
        if (this.state.isLoadingIner) {
            return (
                <div className="row p-2">
                    <div className="col-md-6">
                        <CatalogMagic />
                    </div>
                    <div className="col-md-6">
                        <CatalogMagic />
                    </div>
                </div>
            )
        }else{
            return (
                <div className='pt-4 row'>
                    <MyHelmet title={"Paiement par tranche - " + Constant.APP_DESC} description={Constant.APP_DESC} />
                    <div className="col-lg-9">
                    {this.state.data && this.state.data.list_des_produits && this.state.data.list_des_produits.map((item,index)=>{
                        return(
                            <div className="br-5 bg-white myshadow mb-2 p-2" key={item.id}>
                                <div className="row no-gutters">
                                    <div className="col-2">
                                        <img src={item.imagelink} className="img-fluid" />
                                    </div>
                                    <div className="col-10 row no-gutters">
                                        <div className="col-12 px-1">
                                            <div className="text-uppercase mb-1">N°{item.id}</div>
                                            <div className="text-gray2">{item.title}</div>
                                        </div>
                                        <div className="col-12 row no-gutters">
                                            <div className="col-6" style={{ color: "gray", fontSize: "12px" }}>
                                                <div> Prix Unitaire: <span>{numberFormat(item.amount)} </span></div>
                                                <div> Qte.: <span>{item.qte_produit}</span></div>
                                            </div>
                                            <div className="col-6 text-right">
                                                <div className="text-primary mb-1" style={{ fontSize: "13px" }}> Total: <span>{numberFormat(item.amount * item.qte_produit)} CFA</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {this.state.data && 
                        <div className="br-5 myshadow mb-2 p-2" style={{ background: "rgb(245 119 119)", cursor: "pointer", }}>
                            <div className="row no-gutters step2Cont">
                                <div className="col-6">
                                    Total : {this.state.data.total_a_payer} CFA
                                </div>
                                <div className="col-6 text-right">
                                    Payé : {this.state.data.totalpayer} CFA
                                </div>
                                <div className="col-6">
                                    Reste : {this.state.data.totalrestant} CFA
                                </div>
                                <div className="col-6 text-right">
                                    Tranche Restant : {this.state.data.acompterestant} / {this.state.data.nombre_acompte}
                                </div>
                            </div>
                        </div>
                    }
                    </div>
                    {this.state.data && 
                    <div className="col-lg-3 mt-2 mt-lg-0 mb-2 pl-lg-2" >
                        {this.state.data.totalrestant>0 && 
                        <button disabled={this.state.isPaying} onClick={this.pay} className="btn btn-default w-100 btn-block myshadow text-center text-white py-3 my-shadow step2Cont" style={{ background: "rgb(255, 10, 10)", cursor: "pointer", }}>
                            CONFIRMER LE PAIEMENT DU PROCHAIN ACOMPTE : {numberFormat(this.state.data.prochain_acompte_a_payer)} CFA {this.state.isPaying && <Icofont icon="spinner" spin="true" />}
                        </button>
                        }
                        {this.state.data.totalrestant === 0 &&
                            <div  className="text-center text-white py-3 my-shadow step2Cont" style={{ background: "rgb(0, 255, 0)" }}>
                                SOLDER
                        </div>
                        }
                    </div>
                    }
                </div>

            )
        }
        
    }
}

const mapStateToProps = state => ({ userdata: state.toggleSession.userdata, emoneyaccount: state.toggleSession.emoneyaccount, showModal: state.toggleSession.showModal })
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TrancheDetail);