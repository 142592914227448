
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import notfound from "../assets/notfoun.jpg"
import { Link } from "react-router-dom";

export default class GenericNotFound extends React.Component {
    constructor(props) {
        super(props)
       
    }
    
    render() {
        return (
            <div className="container text-center">
                <img src={notfound} className="img-fluid"/>
                <Link to="/" className="btn btn-primary d-block mb-3">
                    Accueil
                </Link>
            </div>

        )
    }
}