
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import { connect } from "react-redux"
import MyHelmet from "../components/Helmet"
import apicall, { apiform } from "../API/APICALL"
import { ToastContainer, toast } from 'react-toastify';
import Swal from "sweetalert2"
class Parametre extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
        }
        
    }

    componentDidMount() {
        
    }
    __formsubmit = (e) => {
        e.preventDefault()
        
        const formdata = new FormData(e.target);
        formdata.append('user_id', this.props.userdata.id)
        /* var object = {};
        formdata.forEach((value, key) => object[key] = value); */
        
        this.setState({ isLoading: true }, () => {
            apiform(formdata, "updateuserinfos").then(data => {
                this.setState({ isLoading: false })
                if (data.info == "success") {
                    this.__storeusersession(data.data)
                    Swal.fire({
                        icon: 'success',
                        title: 'Enregistrer',
                        text: 'Enregistrement effectué avec succes'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oups',
                        text: data.message
                    })
                }
            })
        })
    }

    __storeusersession = (userdata) => {
        const action = { type: "SAVE_SESSION", value: userdata }
        this.props.dispatch(action)
    }
    __handleReset = (e) => {
        document.getElementById('formid').reset()
    }
    render() {
        
        return (
            <div className='pt-4'>
                <MyHelmet title={"Paramètre - " + Constant.APP_DESC} description={Constant.APP_DESC} />
                <form id="formid" onSubmit={this.__formsubmit} encType="multipart/form-data">
                    <h5 className="font-weight-bold mb-3">Paramètre</h5>
                        
                        <div className="form-group">
                            <label htmlFor="Numero">Numéro:</label>
                            <input type="text" inputMode="tel" className="form-control" id="Numero" disabled defaultValue={this.props.userdata.num_whatsapp} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="prenom">Prénom:</label>
                        <input type="text" inputMode="text" className="form-control" id="prenom" name="prenom" defaultValue={this.props.userdata.prenom} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="Nom">Nom:</label>
                            <input type="text" className="form-control" id="Nom" name="nom" defaultValue={this.props.userdata.name} />
                        </div>
                        <label htmlFor="numero_rechargement" className="mb-2">Numéro de recharge:</label>
                        <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">+229</span>
                        </div>
                        <input type="text" className="form-control" placeholder="Numero de recharge" name="numero_rechargement" defaultValue={this.props.userdata.numero_rechargement}/>
                        </div>
                        <button type="submit" className="btn btn-primary mt-2" disabled={this.state.isLoading}>Enregistrer {this.state.isLoading && <Icofont icon="spinner" spin="true" />}</button>
                    
                </form>
                <ToastContainer />
            </div>

        )
    }
}
const mapStateToProps = state => ({ userdata: state.toggleSession.userdata })
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Parametre);