
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import { Helmet } from "react-helmet";
class Thanku extends React.Component {
    
    render() {
        return (
            <div className='pt-4'>
                <Helmet >
                    <title>Merci pour votre commande</title>
                </Helmet>
                <div className="jumbotron text-center">
                    <Icofont icon="check-circled" size="3" color="green"/>
                    <h3>Merci pour votre commande. Nous prendrons contact avec vous sous peu.</h3>
                    <p className="mt-3 text-info">
                        NB: Si vous avez choisi de payer par ceyron, veuillez réglez votre facture à l'adresse : contact@beninagricole.com  
                    </p>
                </div>
            </div>

        )
    }
}

export default Thanku