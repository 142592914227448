
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import Helmet from "../components/Helmet"
import CatalogMagic from '../components/ShimmerLoader'
import apicall,{getapicall} from "../API/APICALL"
import { Link } from "react-router-dom";
import Countdown from 'react-countdown';
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import {ProductSlider} from "../components/Slider"
import numberFormat from "../function/numberFormat"
import Swal from "sweetalert2"
class Product extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: "",
            isLoading: false,
            data: null,
            quantite:1,
            hidden: false,
            showcart:false,
            isPaying:false
        }

    }
    componentDidMount() {
        this.getproduit() 
        
    }
    getproduit = () => {
            getapicall(`https://ventepro.online/users/function/API/singleproduct.php?id_produit=${this.props.match.params.id}&typevente=${this.props.match.params.type}`).then(data => {
                this.setState({ isLoading: false })
                if (data.info == "success") {
                    this.setState({
                        data: data.data,
                        title: data.data.nomProd,
                    }, () => {
                        window.scrollTo(0, 0)
                    })
                } else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
    }
    
    changeQuantity=(action)=>{
        const {quantite}=this.state
        let newQ
        if (action=="plus") {
            newQ = quantite + 1 < this.state.data.qteRst ? quantite + 1 : this.state.data.qteRst
        } else {
            newQ=quantite>1?(quantite-1):1
        }
        this.setState({
            quantite:newQ
        })
    }

    getSolde = () => {
        if (this.props.emoneyaccount) {
            if (this.props.userdata.emoneyAccount) {
                apicall({ emoney_id: this.props.emoneyaccount.emoney_id, TFac_token_session: this.props.emoneyaccount.TFac_token_session }, 'getsolde')
                    .then((data) => {
                        if (data.info == "success") {
                            this.props.dispacthing({ type: "UPDATE_SOLDE", value: data.nonformatedsolde })
                            this.__cautionner()
                        } else {
                            this.setState({ options: false }, () => {
                                let timerInterval
                                Swal.fire({
                                    title: 'Important',
                                    html: 'Entrez votre pin avant de pouruivre',
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: () => {
                                        Swal.showLoading()
                                        timerInterval = setInterval(() => {
                                            const content = Swal.getContent()
                                            if (content) {
                                                const b = content.querySelector('b')
                                                if (b) {
                                                    b.textContent = Swal.getTimerLeft()
                                                }
                                            }
                                        }, 100)
                                    },
                                    willClose: () => {
                                        clearInterval(timerInterval)
                                    }
                                }).then((result) => {
                                    /* Read more about handling dismissals below */
                                    if (result.dismiss === Swal.DismissReason.timer) {
                                        this.props.history.push(`/portefeuille?callback=produit/${this.props.match.params.id}/type/${this.props.match.params.type}`)
                                    }
                                })
                            })
                        }
                    })
            } else {
                this.props.history.push(`/portefeuille?callback=produit/${this.props.match.params.id}/type/${this.props.match.params.type}`)
            }
        } else {
            this.props.history.push(`/portefeuille?callback=produit/${this.props.match.params.id}/type/${this.props.match.params.type}`)
        }
    }
    
    Sharering=(shareTo)=>{
        let url = window.location.href
        console.log(url)
        switch (shareTo) {
            
            case 'facebook':
                window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url))
                break;
            case 'whatsapp':
                window.open('https://wa.me/?text=' + encodeURIComponent(url))
                break;
        }
    }
    __renderdata = () => {
        const item = this.state.data
        if (this.state.isLoading) {
            return (
                <div className="row p-2">
                    <div className="col-md-6">
                        <CatalogMagic />
                    </div>
                    <div className="col-md-6">
                        <CatalogMagic />
                    </div>
                </div>
            )
        } else {
            if (this.state.data) {
                var inFavorite = this.props.favoriteAnnonces.findIndex((i) => { return i.id === item.id })

                var color = "gray"
                if (inFavorite !== -1) {
                    color = "red"
                }
                return (
                    <div className="row">
                        <div className="col-lg-5">
                            <ProductSlider slide={this.state.data} />
                        </div>
                        <div className="col-lg-7 ">
                            <div className="p-3">
                                <h3 className="text-warning">
                                    <strong>{numberFormat(item.prixProm)}</strong> FCFA
                                </h3>
                                {item.cautionnement_percentage > 0 &&
                                    <h6 className="font-weight-bold text-info">Investissez puis Gagnez {numberFormat(this.__espCaut())} FCFA</h6>
                                }
                                
                                <h4 className="articletitleIn">
                                    <strong>{item.nomProd}</strong>
                                </h4>
                                <div className="ActionBarCont">
                                    <div className="actionBar" hidden={this.state.hidden}>
                                        <div className="btn btn-default jachetebtn text-center py-2 text-white" title="Ajouter au panier" onClick={() => { this.__addToCart() }}>
                                            <Icofont icon={"cart"} /> AJOUTER AU PANIER
                                        </div>
                                        {item.cautionnement_percentage>0 &&
                                            <div className="btn btn-default jachetebtn bg-venteprimary text-center py-2 text-white" title="Je Cautionne" disabled={this.state.isPaying} onClick={() => { this.getSolde() }}>
                                            <Icofont icon={"icofont-user-alt-5"} /> Ou Investissez {this.state.isPaying && <Icofont icon="spinner" spin="true" />}
                                            </div>
                                        }
                                        {this.state.showcart && <div className="ContinueBar">
                                            <div className="ClickZone" onClick={() => { this.setState({ showcart:false})}}>

                                            </div>
                                            <div className="bg-white px-2 py-2 w-100">
                                                <h5 className="" style={{ color: 'black', fontWeight: 900 }}> {this.state.title}</h5>
                                                <h3 className="text-warning">{this.state.quantite} X {numberFormat(item.prixProm)} FCFA</h3>
                                                <div className="shareCont py-2 my-2 text-info">
                                                    {numberFormat(item.prixProm * this.state.quantite)} FCFA
                                                </div>
                                                <Link to="/panier" className="mt-1 btn btn-default blackbtn">VOIR LE PANIER</Link>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className="qterestant pl-0 d-flex justify-content-space-between align-items-center">
                                    Qté. Reste : {item.qteRst}
                                    <Icofont icon={"heart"} onClick={() => { this._toggleFavorite(item) }} style={{ color: color }} />
                                </div>
                                <div className="counterContIn mb-3 mt-2">
                                    <span className="articletitleIn tempsRestant">Temps Promo:</span>
                                    <span className="text-warning font-weight-bold timerIn">
                                    <Countdown
                                        date={item.dateFin}
                                        renderer={this.renderer}
                                        onComplete={() => { this.setState({ hidden: true }) }}
                                    />
                                    </span>
                                </div>
                                <div className="form-row QteCont py-3">
                                    <button className="btn btn-default btnQte mr-3" style={{ background:"#d32f2f"}}  type="button" id="button-minus" onClick={() => { this.changeQuantity("minus")}}> − </button>
                                    <input type="text" className="form-control inputQte" value={this.state.quantite} onChange={(e)=>{this.setState({quantite:e.target.value})}}  />
                                    <button className="btn btn-default btnQte ml-3" style={{ background: "#536dfe" }}  type="button" id="button-plus" onClick={() => { this.changeQuantity("plus") }}> + </button>   
                                    
                                </div>
                                <h6 className="text-gray2">Détail du produit</h6>
                                <p className="mb-2" style={{whiteSpace:'pre-wrap',color:"gray"}}>
                                    {item.descr}
                                </p>
                                <div className="shareCont py-3 my-2">
                                    <h6 className="text-gray2 text-center">Partagez avec vos amis via</h6>
                                    <div className="text-center">
                                        <Icofont icon={"whatsapp"} style={{ color:"#2cb742",cursor:'pointer'}} className="mr-2" title="Partage sur whatsapp" onClick={()=>this.Sharering('whatsapp')}/>
                                        <Icofont icon={"facebook-messenger"} style={{ color: "#3b5998", cursor: 'pointer' }} title="Partage sur Facebook" onClick={() => this.Sharering('facebook')}/>
                                    </div>
                                </div>
                                
                                
                                
                            </div>
                        </div>
                    </div>
                )

            }
        }
    }
    __espCaut=()=>{
        const item = this.state.data
        var prixtotal = item.prixProm * this.state.quantite
        return Math.floor(prixtotal*(item.cautionnement_percentage/100))
    }
    __addToCart=()=>{
        var article = this.state.data
        article.quantite = this.state.quantite
        article.typevente = this.props.match.params.type
        const action = { type: "ADD_TO_CART", value: article }
        this.props.dispacthing(action)
        toast.info("Votre panier à été mise à jour!", {
            position: toast.POSITION.TOP_CENTER
        });
        this.setState({ showcart: true })
    }
    __cautionner=()=>{
        Swal.fire({
            text: `Voulez-vous acheter pour revendre en ligne ce produit puis gagnez ${numberFormat(this.__espCaut())} FCFA après ${this.state.data.jour_cautionnement} jours ?`,
            showCancelButton: true,
            confirmButtonColor: 'green',
            cancelButtonColor: 'red',
            confirmButtonText: 'OUI',
            cancelButtonText: "NON"
        }).then((result) => {
            if (result.isConfirmed) {
                if (this.props.userdata) {
                    var produit = {
                        typevente: this.props.match.params.type,
                        id_produit: this.props.match.params.id,
                        TFac_token_session: this.props.emoneyaccount.TFac_token_session,
                        emoney_id: this.props.emoneyaccount.emoney_id,
                        amout: this.state.data.prixProm,
                        qte_produit: this.state.quantite,
                        cautionnement_percentage: this.state.data.cautionnement_percentage,
                        jour_cautionnement: this.state.data.jour_cautionnement
                    }
                    var totala = this.state.data.prixProm * this.state.quantite
                    if (this.props.userdata.emoneySolde > totala) {
                        this.setState({ isPaying: true }, () => {
                            apicall([produit], 'cautionner')
                            .then((data) => {
                                this.setState({ isPaying: false }, () => {
                                    if (data.info == "success") {
                                        Swal.fire({
                                            icon: 'success',
                                            title: 'Félicitation',
                                            text: 'Cautionnement effectué appelez le +229 67512323 pour confirmation'
                                        })
                                        this.props.history.push('/portefeuille')
                                    } else {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oups',
                                            text: data.message
                                        })
                                    }
                                })
                            })
                        })
                    } else {
                        this.setState({ isPaying: false }, () => {
                            Swal.fire({
                                title: 'Fond insuffisant',
                                text: "Voulez-vous rechargez votre compte?",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'OUI',
                                cancelButtonText: "NON"
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.props.history.push('/portefeuille')
                                }
                            })
                        })
                    }
                } else {
                    this.props.history.push("/se-connecter")
                }
            }
        });
    }
    renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Expirer!</span>
        } else {
            // Render a countdown
            let timer = ''
            if (days > 0) {
                timer = days + " Jr" + (days > 1 ? "s" : "")
            }
            if (hours > 0) {
                timer = timer + " " + hours + "h"
            }
            if (minutes > 0) {
                timer = timer + " " + minutes + "min"
            }
            return (<span>
                {timer} {seconds} Sec
            </span>);
        }
    }
    _toggleFavorite(item) {
        const action = { type: "TOGGLE_FAVORITE", value: item }
        this.props.dispacthing(action)
    }
    render() {
        return (
            <div className="container pt-2 pb-4">
                <Helmet title={this.state.title} description={"Liste des produits et Filtre disponible sur" + Constant.APP_NAME} />
                <h2 className="text-center mb-2 d-none d-lg-block" style={{ color: 'black', fontWeight: 900 }}> {this.state.title}</h2>
                <div className="article br-5" style={{minHeight:"400px",cursor:'default'}}>
                {this.__renderdata()}
                </div>
                <ToastContainer />
            </div>

        )
    }
}
const mapStateToProps = state => ({ cartlist: state.toggleCart.cartList, favoriteAnnonces: state.toggleFavorite.favoriteAnnonces, userdata: state.toggleSession.userdata, emoneyaccount: state.toggleSession.emoneyaccount })
const mapDispatchToProps = (dispatch) => {
    return {
        dispacthing: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Product);