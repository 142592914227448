
import React from 'react'
import logo from '../assets/logo.png';
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import apicall from "../API/APICALL"
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from "react-router-dom";
class MobileFooter extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            bottom: 0,
            display:false,
        }
        this.prevPos=0
    }
    componentDidMount() {
        if (typeof window !== "undefined") {
            window.onscroll = () => {
                let currentScrollPos = window.pageYOffset;
                let maxScroll = document.body.scrollHeight - window.innerHeight;
                if (currentScrollPos > this.prevPos) {
                    this.setState({ bottom: "-60px" })
                } else {
                    this.setState({ bottom: "0px" })
                }
                this.prevPos = currentScrollPos
            }
        }
    }
    
    reset=()=>{
        this.setState({ display: false })
    }
    condition=()=>{
        this.setState({ display: false },()=>{
            this.props.history.push("/terms")
        })
    }
    
     render(){
         return(
            <div className="d-lg-none">
                <div className="mobile-menu" style={{bottom:this.state.bottom}}>
                    <NavLink to="/" className="text-black text-center p-2" activeClassName="selectedLink2" >
                         <Icofont icon="food-cart" className="btnicon" size="2" />
                        <div className="title">
                            Annonces
                        </div>
                    </NavLink>
                    <NavLink to="/mes-favoris" className="text-black text-center p-2" activeClassName="selectedLink2" >
                        <Icofont icon="heart" className="btnicon" size="2" />
                        <div className="title">
                            Favoris
                        </div>
                    </NavLink>
                    <NavLink to="/portefeuille" className="text-black text-center p-2" activeClassName="selectedLink2" >
                        <Icofont icon="icofont-wallet" className="btnicon" size="2" />
                        <div className="title">
                            Portefeuille
                        </div>
                    </NavLink>
                        <span className={`text-black text-center p-2 plusbtn ` + (this.state.display ? "activebtn":"")} onClick={() => { this.setState({ display: !this.state.display}) }} >
                            <Icofont icon={!this.state.display ? "listine-dots" :"caret-down"} className="btnicon" size="2" />
                        <div className="title">
                            {!this.state.display?'Plus':"Moins"}
                        </div>
                    </span>
                </div>
                <div className="plusCont" hidden={!this.state.display} >
                     <div className="container font-weight-bold pt-2" style={{color:"gray"}}>
                        <h6>Contactez-nous</h6>
                         <a href="https://wa.me/+22967512323" className="btn btn-default text-black font-weight-bold bg-white d-block w-75 mb-2 text-left">Contactez-nous par whatsapp</a>
                         <a href="mailto:cooperationsofficesarl@gmail.com" className="btn btn-default text-black font-weight-bold bg-white d-block w-75 mb-2 text-left">Contactez-nous par mail</a>
                         <h6>Informations</h6>
                         <button className="btn btn-default text-black font-weight-bold bg-white d-block w-75 mb-2 text-left" onClick={this.condition}>Conditions Générales d'utilisation</button>
                        
                         <a href="https://play.google.com/store/apps/details?id=com.ventepro.office" target="blank" className="btn btn-default text-black font-weight-bold bg-white d-block w-75 mb-2 text-left">Application mobile</a>
                    </div>
                </div>
            </div>
            )
     }
}

const mapStateToProps = state => ({ cartlist: state.toggleCart.cartList })

export default connect(mapStateToProps)(withRouter(MobileFooter));