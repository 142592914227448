
import React from 'react'
import Constant from "../constants/constant"
import {connect} from "react-redux"
import MyHelmet from "../components/Helmet"
import apicall from "../API/APICALL"
import CatalogMagic from '../components/ShimmerLoader'
import { ToastContainer, toast } from 'react-toastify';
import ResumeArticle from "../components/ResumeArticle"
class ProduitAchetes extends React.Component {
    constructor(props) {
        super(props)
       this.state={
           isLoadingIner:false,
           data: []
       }
        this.resetElement=''
    }
    componentDidMount(){
        this.loadData()
    }
    loadData=()=>{
        if (this.props.emoneyaccount) {
            if (this.props.userdata.emoneyAccount) {
                this.setState({ isLoadingIner: true }, () => {
                    apicall({ emoney_id: this.props.emoneyaccount.emoney_id, TFac_token_session: this.props.emoneyaccount.TFac_token_session, listtype:"achat" }, 'getalltransactions')
                        .then((data) => {
                            this.setState({ isLoadingIner: false })
                            if (data.info == "success") {
                                this.setState({data:data.data})
                            } else {
                                if (data.message === "INVALID_TFac_token_session" || data.message ==="EXPIRED_SESSION_TFAC") {
                                    this.props.dispatch({ type: "OPEN_MODAL" })
                                } else {
                                    toast.error(data.message, {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }

                            }
                        })
                })
            } else {
                this.props.dispatch({ type: "OPEN_MODAL" })
            }
        } else {
            this.props.dispatch({ type: "OPEN_MODAL" })
        }
    }
   
    render() {
        if (this.state.isLoadingIner) {
            return (
                <div className="row p-2">
                    <div className="col-md-6">
                        <CatalogMagic />
                    </div>
                    <div className="col-md-6">
                        <CatalogMagic />
                    </div>
                </div>
            )
        }else{
            return (
                <div className='pt-4'>
                    <MyHelmet title={"Produit achetés - " + Constant.APP_DESC} description={Constant.APP_DESC} />
                    {this.state.data.map((item,index)=>{
                        return(
                            <ResumeArticle item={item} key={index}/>
                        )
                    })}
                </div>

            )
        }
        
    }
}

const mapStateToProps = state => ({ userdata: state.toggleSession.userdata, emoneyaccount: state.toggleSession.emoneyaccount, showModal: state.toggleSession.showModal })
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProduitAchetes);