
import React from 'react'
import Constant from "../constants/constant"
import { connect } from "react-redux"
import MyHelmet from "../components/Helmet"
import apicall from "../API/APICALL"
import Icofont from 'react-icofont';
import CatalogMagic from '../components/ShimmerLoader'
import { ToastContainer, toast } from 'react-toastify';
class MesActivites extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoadingIner: false,
            data: []
        }
        this.resetElement = ''
    }
    componentDidMount() {
        this.loadData()
    }
    loadData = () => {
        if (this.props.emoneyaccount) {
            if (this.props.userdata.emoneyAccount) {
                this.setState({ isLoadingIner: true }, () => {
                    apicall({ emoney_id: this.props.emoneyaccount.emoney_id, TFac_token_session: this.props.emoneyaccount.TFac_token_session, }, 'getalltransactions')
                        .then((data) => {
                            this.setState({ isLoadingIner: false })
                            if (data.info === "success") {
                                this.setState({ data: data.data })
                            } else {
                                if (data.message === "INVALID_TFac_token_session" || data.message === "EXPIRED_SESSION_TFAC") {
                                    this.props.dispatch({ type: "OPEN_MODAL" })
                                } else {
                                    toast.error(data.message, {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }

                            }
                        }).catch((error)=>{})
                })
            } else {
                this.props.dispatch({ type: "OPEN_MODAL" })
            }
        } else {
            this.props.dispatch({ type: "OPEN_MODAL" })
        }
    }
    getIcon=(item)=>{
        switch (item.type_transaction) {
            case "retrait":
                return "money"
                break;
            case "recharge":
                return "credit-card"
                break;
            default:
                return "cart"
                break;
        }
    }
    getAmount = (item) => {
        switch (item.type_transaction) {
            case "retrait":
                return item.amount
                break;
            case "recharge":
                return item.amount
                break;
            case "achat":
                return item.totalamount
                break;
            case "cautionnement":
                return item.totalamount
                break;
            case "reservationbonus":
                return item.amount
                break;
            case "reservationwheelgame":
                return item.amount
                break;
            case "RECHARGE_AUTRE_COMPTE":
                return item.amount
                break;
            default:
                return item.amount
                break;
        }
    }
    getDetails = (item) => {
        switch (item.type_transaction) {
            case "retrait":
                return "Retrait d'argent"
                break;
            case "recharge":
                return `Recharge par ${item.msisdnUtiliser} (Mon compte)`
                break;
            case "achat":
                return (
                    <span>
                        Achat
                        {item.etatachatcaut === "TRANSACTION_CANCELED" && 
                            <span style={{ color: "red" }}> (Annulé <Icofont icon="close-line" />)</span>
                        }
                        {item.etatachatcaut === "TRANSACTION_ACCEPTED" &&
                            <Icofont icon="check" style={{ color: "green", fontSize: "12px"}} />
                        }
                        {item.etatachatcaut === "WAITING_CONFIRMATION" &&
                            <span> (En cour)</span>
                        }
                    </span>
                )
                break;
            case "cautionnement":
                return (
                    <span>
                        Cautionnement
                        {item.etatachatcaut === "TRANSACTION_CANCELED" &&
                            <span style={{ color: "red" }}> (Annulé <Icofont icon="close-line" />)</span>
                        }
                        {item.etatachatcaut === "TRANSACTION_ACCEPTED" &&
                            <Icofont icon="check" style={{ color:"green", fontSize:"12px"}}/>
                        }
                        {item.etatachatcaut === "WAITING_CONFIRMATION" &&
                            <span> (En cour)</span>
                        }
                    </span>
                )
                break;
            case "reservationbonus":
                return "Réservation de bonus"
                break;
            case "reservationwheelgame":
                return "Réservation de code remise"
                break;
            case "RECHARGE_AUTRE_COMPTE":
                return `Recharge par ${item.msisdnUtiliser} (Autre compte)`
                break;
            case "acompte":
                return `Paiement acompte`
                break;
            default:
                return "Activites"
                break;
        }
    }

    render() {
        if (this.state.isLoadingIner) {
            return (
                <div className="row p-2">
                    <div className="col-md-6">
                        <CatalogMagic />
                    </div>
                    <div className="col-md-6">
                        <CatalogMagic />
                    </div>
                </div>
            )
        } else {
            return (
                <div className='pt-4 bg-white'>
                    <MyHelmet title={"Produit cautionnés - " + Constant.APP_DESC} description={Constant.APP_DESC} />
                    {this.state.data.map((item, index) => {
                        return (
                            <div className="row mb-2 px-2 no-gutters" key={index}>
                                <div className="col-2">
                                    <div className="iconcont">
                                        <Icofont icon={this.getIcon(item)} size="2" />
                                    </div>
                                </div>
                                <div className="col-7 px-1">
                                    <div className="text-black font-size-11">
                                        {item.transactionRef}
                                    </div>
                                    <div style={{ color: "gray", fontSize: "10px" }}> {this.getDetails(item)}</div>
                                </div>
                                <div className="col-3 text-right">
                                    <div className="text-black font-size-11">{this.getAmount(item)} FCFA</div>
                                    <div style={{ color: "gray", fontSize: "10px" }}> {item.date_created}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>

            )
        }

    }
}

const mapStateToProps = state => ({ userdata: state.toggleSession.userdata, emoneyaccount: state.toggleSession.emoneyaccount, showModal: state.toggleSession.showModal })
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MesActivites);