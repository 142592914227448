
import React from 'react'
import logo from '../assets/logo.png';
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import apicall from "../API/APICALL"
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from "react-router-dom";
class Navbar extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            searchValue: ""
        }
    }
    __updateInputValue=(e)=>{
        this.setState({ searchValue: e.target.value });
    }
    
    
    gotocatalogue=()=>{
        if(this.state.searchValue.length>0){
            this.props.history.push({
                pathname: '/catalogue',
                state: {query:this.state.searchValue}
            })
        }
    }
    __toggleReseBtn = () => {
        if (this.state.searchValue.length > 0) {
            return "d-block"
        } else {
            return "d-none"
        }
    }
    
    
     render(){
         return(
             <div className="header-top-section">
                 <div className="bg-bluev shadow-xxl flex-column header-nav-wrapper navbar-light">
                     <div className="MuiContainer-root d-flex align-items-center py-1 MuiContainer-maxWidthLg">
                         <div className="app-nav-logo flex-grow-0">
                             
                             <NavLink exact to="/" className="app-nav-logo app-nav-logo--dark" title={Constant.APP_NAME}>
                                 <div className="app-nav-logo--icon" style={{background:'transparent'}}><img  src={logo} className="logoimage"/></div>
                             </NavLink>
                         </div>
                         <div className="header-nav-menu flex-grow-1 ml-auto d-block pcsearchCont">
                             <ul className="d-flex justify-content-center">
                                 <form  onSubmit={(e)=>{e.preventDefault()}} id='bigform' className="d-flex flex-grow-1 position-relative" >
                                     <div className="find d-flex flex-grow-1" style={{ background: 'white' }}>
                                         <Icofont icon="icofont-search-2"  className="searchicon"/>
                                         <input defaultValue={this.state.searchValue} type="text" name="q" placeholder="Que recherchez-vous?" className="searchInput" onKeyUp={(e) => { this.__updateInputValue(e) }} autoComplete="off" required="" />
                                         <button type="button" onClick={this.gotocatalogue} className={this.__toggleReseBtn()+" closeicon"}>
                                             <Icofont icon="arrow-right" />
                                        </button>
                                     </div>
                                     
                                </form>
                             </ul>
                         </div>
                         <div className="header-nav-actions ml-auto ml-lg-3 ml-lg-4 flex-grow-0">
                             <NavLink to="/portefeuille" className="mr-3 d-lg-inline-flex text-white d-none" activeClassName="selectedLink" >
                                 <Icofont icon="icofont-wallet" className="btnicon"  />
                                 <span className="ml-2  d-lg-flex">
                                     Portefeuille
                                </span>
                             </NavLink>
                             <NavLink to="/panier" className="d-inline-flex text-white" activeClassName="selectedLink" style={{position:"relative"}} >
                                 <span id="mybadge">{this.props.cartlist.length}</span>
                                 <Icofont icon="icofont-opencart" className="btnicon btniconcart" />
                                 <span className="ml-2 d-none d-lg-flex">
                                     Panier
                                </span>
                             </NavLink>
                         </div>
                     </div>
                 </div>
                 
                 </div>

            )
     }
}

const mapStateToProps = state => ({ cartlist: state.toggleCart.cartList })

export default connect(mapStateToProps)(withRouter(Navbar));