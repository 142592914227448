import React, { Component } from "react";
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";

export default class SimpleSlider extends Component {
    render() {
        const images = this.props.slide
        return (
            <Carousel className="slidercontainer">
                {images.map(function (item, index) {
                    return (
                        <Carousel.Item key={index}>
                            <img
                                className="d-block w-100"
                                src={item}
                            />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        )
    }
}

export class ProductSlider extends React.PureComponent {
    render() {
        const item = this.props.slide
        var imgs=[]
        for (let index = 1; index < 5; index++) {
            if(item['img'+index]){
                imgs.push(item['img' + index])
            }
        }
        return (
            <Carousel>
                {imgs.map(function (img, index) {
                    return (
                        <Carousel.Item key={index}>
                            <img
                                className="d-block w-100"
                                src={img}
                                alt={item.nomProd}
                            />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        )
    }
}