import React, { Component } from "react";
import Article from "./Article"
export default class RecentProductSlider extends Component {
    render() {
        
        return (
            <div className="row no-gutters">
                {this.props.product.map((item, index) => {
                    return (
                        <div className="col-6 col-md-3" key={item.id}>
                        <Article item={item}  />
                    </div>
                    )
                })}
            </div>
        );
    }
}