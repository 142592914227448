import Constant from "../constants/constant"

export default function apicall(object,action) {

    var obj = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Host': Constant.HOST,
        },
        body: JSON.stringify(object)
    }
    return fetch(Constant.HOST + '/API/' + action + "/" + Constant.APP_KEY, obj)
        .then(function (res) {
            const objres = res.json()
            return objres;
        })
        .catch(function (error) {
            console.log(error);
        })
}

export  function postapicall(object, link) {

    var obj = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(object)
    }
    return fetch(link, obj)
        .then(function (res) {
            const objres = res.json()
            return objres;
        })
        .catch(function (error) {
            console.log(error);
        })
}
export function getapicall(link) {
    return fetch(link)
        .then(function (res) {
            const objres = res.json()
            return objres;
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function apiform(formdata, action) {

    var obj = {
        method: 'POST',
        headers: {
            'Host': Constant.HOST
        },
        body: formdata
    }
    return fetch(Constant.HOST + '/API/' + action + "/" + Constant.APP_KEY, obj)
        .then(function (res) {
            const objres = res.json()
            return objres;
        })
        .catch(function (error) {
            console.log(error);
        })
}