
const initialState = { tempWinner: null }

function saveWinnerFunction(state = initialState, action) {
    let nextState
    switch (action.type) {
        case "SAVE_CODE":
            nextState = {
                ...state,
                tempWinner: action.value
            }
            return nextState || state
            break;
        default:
            return state
            break;
    }
}

export default saveWinnerFunction