
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import apicall from "../API/APICALL"
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer, toast } from 'react-toastify';
import { storeData, getData} from "../function/Session"
import MyHelmet from "../components/Helmet"
import RegionSelect from 'react-region-flag-select';
class VerifyPin extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            isLoading:false,
            pin:"",
            isLoading2:false
        }
       
    }

    componentWillMount() {
        if (this.props.pinid){
            if (this.props.userdata) {
                this.props.history.push("/portefeuille/paiement-par-tranche")
            }
        }else{
            this.props.history.push("/se-connecter")
        }
    }

    __login=()=>{
        apicall({ numero: "+" + this.props?.location?.state?.phone},'logincoop')
        .then((data)=>{
            if (data.info == "success") {
                this.props.dispatch({ type: "SAVE_SESSION", value: data.data })
                this.props.dispatch({ type: "DELETE_PIN" })
                this.props.history.push("/portefeuille/paiement-par-tranche")
            } else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })
    }
    __verifyCode=()=>{
        this.setState({ isLoading: true }, () => {
                
            apicall({ otp_id: this.props.pinid, code: this.state.pin }, 'verifyotp').then(data => {
                this.setState({ isLoading: false })
                if (data.info == "success") {
                    if (data && data.message.data.verified) {
                        this.__login()
                    } else {
                        toast.error("Code Pin incorrect.", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        this.setState({ isLoading: false })
                    }
                } else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })  
        })  
    }
    __storePinId=(pinData)=>{
         const action = { type: "SAVE_PIN", value: pinData}
         this.props.dispatch(action)
    }
    resendCode=()=>{
        this.setState({ isLoading2: true }, () => {
            apicall({ otp_id: this.props.pinid }, 'resendotp').then(data => {
                this.setState({ isLoading2: false })
                if (data.info == "success") {
                    toast.success("Code renvoyer.", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
        })

    }
    updatePin = (e) => {
        this.setState({ pin: e.target.value }) 
    }
    
    render() {
        return (
            <div className="container auth-wrapper">
                <MyHelmet title={"Connexion - " + Constant.APP_DESC} description={Constant.APP_DESC} />
                <div className="auth-inner">
                    <form>
                        <p className="my-2 text-center">Entrez le code pin envoyé au <span className="font-weight-bold">{this.props?.location?.state?.phone}</span></p>
                        <div className="text-info text-center mb-2"><Link to="/se-connecter" >Cliquez ici si ce n'est pas votre numéro.</Link></div>

                        <div className="form-group">
                            <input maxLength="4" type="text" inputMode="numeric" value={this.state.pin} className="form-controlC" placeholder={"Entrez le pin envoyé"} onChange={(event) => { this.updatePin(event) }} required/>
                        </div>

                        <button type="button" className="btn btn-primary btn-block mb-2" disabled={this.state.isLoading} onClick={(event) => { this.__verifyCode(event) }} >Vérifier le code {this.state.isLoading && <Icofont icon="spinner" spin="true" />}</button>
                        <div className="text-center text-primary"><span style={{ cursor: 'pointer' }} onClick={() => { this.resendCode() }} disabled={this.state.isLoading2}>Renvoyer le code {this.state.isLoading2 && <Icofont icon="spinner" spin="true" />}</span></div>
                    </form>
                </div>
                <ToastContainer />
            </div>

        )
    }
}

const mapStateToProps = state => ({ userdata: state.toggleSession.userdata, pinid: state.savePin.pinid })
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(VerifyPin);