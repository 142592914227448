import React from "react";
import { Helmet } from "react-helmet";

export default class MyHelmet extends React.Component {
    render() {
        return (
            <Helmet>
                <title>{this.props.title}</title>
                <meta name="description" content="{this.props.description}" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
        );
    }
};