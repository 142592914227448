
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import PaiementParTranche from "./PaiementParTranche"
import ProduitAchetes from "./ProduitAchetes"
import ProduitCautionner from "./ProduitCautionner"
import MesActivites from "./MesActivites"
import TrancheDetail from "./TrancheDetail"
import Parametre from "./Parametre"
import Recharge from "./Recharge"
import Modal from 'react-bootstrap/Modal';
import MyHelmet from "../components/Helmet"
import {connect} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter as Router, Switch, Route, Redirect, NavLink } from "react-router-dom";
import apicall from "../API/APICALL"
import Swal from "sweetalert2"
class Portefeuille extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            isLoading:false,
            isLoading2:false,
            recoveryModal:false,
            isLoadingbg:false,
            gettingSolde:false,
            createnewaccount:false,
            pin:"",
            pinConfirm:"",
            smspin:"",
            newpin: "",
            newpinc: "",
            pinToken:null
        }
        this.callback=""
    }
    componentDidMount(){
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has('callback')) {
            this.callback = urlParams.get('callback')
        }
        this.props.history.push("/portefeuille/paiement-par-tranche")
        this.getSolde()
    }
    __renderRoute=()=>{
        const { path } = this.props.match
        return (
            <div className="col-md-8">
                <div className="dashmenulinkCont mt-3 mt-lg-0">
                {this.__rednderMenu()}
                </div>
                <Route exact path={`/portefeuille/paiement-par-tranche`} component={PaiementParTranche} />
                <Route path={`/portefeuille/paiement-par-tranche/:id`} render={(props )=>{
                    return <TrancheDetail {...props} getSolde={this.getSolde} />
                }} />
                <Route path={`/portefeuille/produit-achtetes`} component={ProduitAchetes} />
                <Route path={`/portefeuille/produit-cautionnes`} component={ProduitCautionner} />
                <Route path={`/portefeuille/activite`} component={MesActivites} />
                <Route path={`/portefeuille/parametre`} component={Parametre} />
                <Route path={`/portefeuille/recharge`} component={Recharge} />
            </div>
        )
    }
    __rednderMenu=()=>{
        return Constant.dashbordmenu.map((item,index)=>{
            return(
                <NavLink to={"/portefeuille"+ item.link} key={index} className="dashmenulink" activeClassName="dahsmenuactive">
                        {item.name}
                </NavLink>
            )
        })
    }
    handleClose=()=>{
        this.setState({ recoveryModal:false})
        this.props.dispatch({ type: "CLOSE_MODAL"})
    }
    getSolde=()=>{
        if(this.props.emoneyaccount){
            if (this.props.userdata.emoneyAccount) {
                this.setState({ gettingSolde: true }, () => {
                    apicall({ emoney_id: this.props.emoneyaccount.emoney_id, TFac_token_session: this.props.emoneyaccount.TFac_token_session }, 'getsolde')
                        .then((data) => {
                            this.setState({ gettingSolde: false })
                            if (data.info == "success") {
                                this.props.dispatch({ type: "UPDATE_SOLDE", value: data.nonformatedsolde })
                            } else {
                                if (data.message === "INVALID_TFac_token_session" || data.message === "EXPIRED_SESSION_TFAC"){
                                    this.props.dispatch({ type: "OPEN_MODAL" })
                                }else{
                                    toast.error(data.message, {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                                
                            }
                        })
                })
            } else {
                this.setState({
                    createnewaccount: true
                }, () => { this.props.dispatch({ type: "OPEN_MODAL" })})
            }
        }else{
            if (this.props.userdata.emoneyAccount) {
                this.props.dispatch({ type: "OPEN_MODAL" })
            } else {
                this.setState({
                    createnewaccount: true
                }, () => { this.props.dispatch({ type: "OPEN_MODAL" }) })
            }
        }
    }
    emoneyLogin=()=>{
        this.setState({isLoading:true},()=>{
            apicall({ user_id: this.props.userdata.id,pin:this.state.pin }, 'login')
                .then((data) => {
                    if (data.info == "success") {
                        this.props.dispatch({ type: "SAVE_EMONEY_TOKEN", value: { emoney_id: data.emoney_id, TFac_token_session: data.TFac_token_session} })
                        
                        if (this.state.createnewaccount){
                            apicall({ numero: this.props.userdata.num_whatsapp }, 'logincoop')
                                .then((data) => {
                                    if (data.info == "success") {
                                        this.props.dispatch({ type: "SAVE_SESSION", value: data.data })
                                        this.props.dispatch({ type: "CLOSE_MODAL" })
                                        this.props.history.push("/portefeuille/parametre")
                                    } else {
                                        toast.error("Veuillez fournir un pin a 4 chiffres valide", {
                                            position: toast.POSITION.TOP_RIGHT
                                        });
                                    }
                                })
                        }else{
                            this.setState({ isLoading: false })
                            this.props.dispatch({ type: "CLOSE_MODAL" })
                            if(this.callback.length>0){
                                this.props.history.push("/"+this.callback)
                            }
                        }
                    } else {
                        this.setState({ isLoading: false })
                        toast.error("Veuillez fournir un pin a 4 chiffres valide", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                })
        })
    }
    recoverPin=()=>{
        
        this.setState({ isLoading2: true }, () => {
            apicall({ numero: this.props.userdata.num_whatsapp }, 'sendotp').then(data => {
                this.setState({ isLoading2: false })
                if (data.info == "success") {
                    this.setState({ pinToken: data.message.data.id, recoveryModal: true, isLoading2: false })
                } else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
        })

    }
    __CHangePin=()=>{
        if (this.state.pinToken){
            this.setState({ isLoading: true }, () => {
                apicall({ otp_id: this.state.pinToken, code: this.state.smspin }, 'verifyotp').then(data => {
                    this.setState({ isLoading: false })
                    if (data.info == "success") {
                        if (data && data.message.data.verified) {
                            apicall({ user_id: this.props.userdata.id, pin: this.state.newpin, pinconfirm: this.state.newpinc }, 'updatepin')
                                .then((data) => {
                                    if (data.info == "success") {
                                        Swal.fire({
                                            text: `Pin changé avec succès!`,
                                            icon: 'success',
                                            showCancelButton: true,
                                            confirmButtonColor: 'green',
                                            cancelButtonColor: 'red',
                                            confirmButtonText: 'Se connecter',
                                            cancelButtonText: "Annuler"
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                this.setState({ recoveryModal: false, isLoading: false })
                                                this.props.dispatch({ type: "OPEN_MODAL" })
                                            } else {
                                                this.handleClose()
                                                this.setState({ isLoading: false })
                                            }
                                        });
                                    } else {
                                        this.setState({ isLoading: false })
                                        toast.error(data.message, {
                                            position: toast.POSITION.TOP_RIGHT
                                        });
                                    }
                                })
                        } else {
                            toast.error("Code Pin SMS incorrect.", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            this.setState({ isLoading: false })
                        }
                    } else {
                        toast.error(data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                })
            })
        }
    }
    newAccount=()=>{
        if (this.state.pinConfirm == this.state.pin){
            this.emoneyLogin()
        }
    }
    
    render() {
        const {  url } = this.props.match
        const show=true
        return (
            <div className="container pb-3">
                <MyHelmet title={"Portefeuille - " + Constant.APP_DESC} description={Constant.APP_DESC} />
                <ToastContainer/>
                <div className={"row"}>
                    <div className="col-md-4 text-center">
                        <div className="categorie-container">
                            <div className="profilImg" style={{ backgroundImage: `url(${this.props.userdata.photo})`}}></div>
                            <h5 className="nameProfil text-center mt-2">{this.props.userdata.name + ' ' + this.props.userdata.prenom}</h5>
                            <h6 className="numProfil text-center">{this.props.userdata.num_whatsapp}</h6>
                            <div className="font-weight-bold text-center d-flex justify-content-center align-items-center">
                                {this.props.userdata.emoneySolde} FCFA <span onClick={()=>{this.getSolde()}} className="text-warning ml-2" style={{cursor:"pointer"}}><Icofont icon="refresh" spin={this.state.gettingSolde} size="2" /></span>
                            </div>
                            <div className="SettingClass text-black" onClick={()=>{this.props.history.push('/portefeuille/parametre')}}>
                                <Icofont icon="settings"  />
                            </div>
                            <button className="mt-1 btn btn-info btn-sm" onClick={() => this.props.history.push('/portefeuille/recharge')}>RECHARGER VOTRE COMPTE</button>
                        </div>
                    </div>
                    {this.__renderRoute()}
                    
                    
                        <Modal show={this.props.showModal ? true : false} onHide={this.handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title><div className="text-center">VOTRE CODE PIN</div></Modal.Title>
                            </Modal.Header>
                        {!this.state.createnewaccount && !this.state.recoveryModal &&
                            <Modal.Body>
                                <span className="font-weight-bold mb-2">Entrez votre code pin (04 chiffres)</span>

                                <div className="form-group">
                                    <input minLength="4" maxLength="4" type="text" value={this.state.pin} onChange={(event) => { this.setState({ pin: event.target.value }) }} inputMode="numeric" className="form-controlC" placeholder={"Il s'agit du pin d'accès au portefeuille"} required />
                                </div>
                            <div className=" text-center my-2"><span style={{ cursor: 'pointer' }} className="text-danger" disabled={this.state.isLoading2} onClick={() => { this.recoverPin()}}>Code pin oublié? {this.state.isLoading2 && <Icofont icon="spinner" spin="true" />}</span></div>

                                <button type="button" className="btn btn-primary btn-block mb-2" disabled={this.state.isLoading} onClick={() => { this.emoneyLogin() }} >VALIDER {this.state.isLoading && <Icofont icon="spinner" spin="true" />}</button>

                            </Modal.Body>
                        }
                        {!this.state.createnewaccount && this.state.recoveryModal && 
                            <Modal.Body>
                                <p style={{ color: 'gray', fontSize: 12 }}>Le code Pin à ' chiffres est créé par l'utilisateur. Il est unique, personnel, strictement confidentiel et différent du code d'authentification à 4 chiffres (généré automatiquement et envoyé par sms à votre numéro mobile)</p>
                                <span className="font-weight-bold mb-2">Entrez le code reçu par sms</span>

                                <div className="form-group">
                                    <input maxLength="4" type="text" value={this.state.smspin} onChange={(event) => { this.setState({ smspin: event.target.value }) }} inputMode="numeric" className="form-controlC" placeholder={"Entrez le code reçu par sms"} required />
                                </div>
                                <span className="font-weight-bold mb-2">Entrez votre nouveau code pin (04 chiffres)</span>

                                <div className="form-group">
                                    <input maxLength="4" type="text" value={this.state.newpin} onChange={(event) => { this.setState({ newpin: event.target.value }) }} inputMode="numeric" className="form-controlC" placeholder={"Entrez votre nouveau code pin (04 chiffres)"} required />
                                </div>
                                <span className="font-weight-bold mb-2">Confirmez le nouveau code pin</span>

                                <div className="form-group">
                                    <input maxLength="4" type="text" value={this.state.newpinc} onChange={(event) => { this.setState({ newpinc: event.target.value }) }} inputMode="numeric" className="form-controlC" placeholder={"Confirmez le nouveau code pin"} required />
                                </div>
                            <div className=" text-center my-2"><span style={{ cursor: 'pointer' }} className="text-danger" onClick={() => { this.setState({ recoveryModal: false })}}>Annuler</span></div>

                                <button type="button" className="btn btn-primary btn-block mb-2" disabled={this.state.isLoading} onClick={() => { this.__CHangePin() }} >Valider {this.state.isLoading && <Icofont icon="spinner" spin="true" />}</button>

                            </Modal.Body>
                        }
                        {this.state.createnewaccount && 
                            <Modal.Body>
                                <span className="font-weight-bold mb-2">Entrez un code pin (04 chiffres)</span>

                                <div className="form-group">
                                    <input minLength="4" maxLength="4" type="text" value={this.state.pin} onChange={(event) => { this.setState({ pin: event.target.value }) }} inputMode="numeric" className="form-controlC" placeholder={"Il s'agit du pin d'accès au portefeuille"} required />
                                </div>
                                <span className="font-weight-bold mb-2">Confirmer votre pin</span>

                                <div className="form-group">
                                <input minLength="4" maxLength="4" type="text" value={this.state.pinConfirm} onChange={(event) => { this.setState({ pinConfirm: event.target.value }) }} inputMode="numeric" className="form-controlC" placeholder={"Confirmer votre pin"} required />
                                </div>
                                <div className=" text-center my-2"><span style={{ cursor: 'pointer' }} className="text-danger" disabled={this.state.isLoading2} onClick={() => { this.recoverPin() }}>Code pin oublié? {this.state.isLoading2 && <Icofont icon="spinner" spin="true" />}</span></div>

                                <button type="button" className="btn btn-primary btn-block mb-2" disabled={this.state.isLoading} onClick={() => { this.newAccount() }} >VALIDER {this.state.isLoading && <Icofont icon="spinner" spin="true" />}</button>

                            </Modal.Body>
                        }
                            <Modal.Footer>
                            </Modal.Footer>
                        </Modal>
                    
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => ({ userdata: state.toggleSession.userdata, emoneyaccount: state.toggleSession.emoneyaccount, showModal: state.toggleSession.showModal})
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Portefeuille);