
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import apicall from "../API/APICALL"
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer, toast } from 'react-toastify';
import { storeData, getData} from "../function/Session"
import MyHelmet from "../components/Helmet"
import RegionSelect from 'react-region-flag-select';
class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            isLoading:false,
            code:229,
            phone:""
        }
       
    }

    componentWillMount() {
        if (this.props.userdata) {
            this.props.history.push("/portefeuille/paiement-par-tranche")
        }
    }

    _sendCode=()=>{
        if (this.state.phone.toString().length>7){
            this.setState({ isLoading: true }, () => {
                
                apicall({ numero: this.state.code.toString() + this.state.phone }, 'sendotp').then(data => {
                    this.setState({ isLoading: false })
                    if (data.info == "success") {
                        this.__storePinId(data.message.data.id)
                        this.props.history.push({
                            pathname : "/verifypin",
                            state : {phone : this.state.code.toString() + this.state.phone}
                        });
                    } else {
                        toast.error(data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                })
            })
        }else{
            toast.error("Numéro invalide!", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        
    }
     __storePinId=(pinData)=>{
         const action = { type: "SAVE_PIN", value: pinData}
         this.props.dispatch(action)
    }
    updatePhone = (e) => {
        if (parseInt(e.target.value)){
            this.setState({ phone: parseInt(e.target.value) }) 
        }else{
            if (e.target.value == 0 || e.target.value==""){
                this.setState({ phone: e.target.value }) 
            }
        }
        
    }
    
    render() {
        return (
            <div className="container auth-wrapper">
                <MyHelmet title={"Connexion - " + Constant.APP_DESC} description={Constant.APP_DESC} />
                <div className="auth-inner">
                    <form>
                        <h4 className="text-center">{Constant.APP_NAME}</h4>

                        <div className="form-group numberselect">
                            <RegionSelect isFlag={false} countryOnly={true} selectedCountryCode={'BJ'} handleChange={(data) => { this.setState({ code: data.countryData.data.phoneCode})}}/>
                        </div>

                        <div className="form-group">
                            <input type="text" inputMode="numeric" value={this.state.phone} className="form-controlC" placeholder="Entrez le numéro de Téléphone" onChange={(event) => { this.updatePhone(event) }} required/>
                        </div>

                        <button type="button" className="btn btn-primary btn-block" disabled={this.state.isLoading} onClick={(event) => { this._sendCode(event) }} >Connexion {this.state.isLoading && <Icofont icon="spinner" spin="true" />}</button>
                        
                    </form>
                </div>
                <ToastContainer />
            </div>

        )
    }
}

const mapStateToProps = state => ({ userdata: state.toggleSession.userdata })
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);