
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import {connect} from "react-redux"
import MyHelmet from "../components/Helmet"
import apicall from "../API/APICALL"
import numberFormat from '../function/numberFormat'
import CatalogMagic from '../components/ShimmerLoader'
import { ToastContainer, toast } from 'react-toastify';
import fullcart from '../assets/fullcart.png'
import { Link } from "react-router-dom";
class PaiementParTranche extends React.Component {
    constructor(props) {
        super(props)
       this.state={
           isLoadingIner:false,
           data: []
       }
        this.resetElement=''
    }
    componentDidMount(){
        this.loadData()
    }
    loadData=()=>{
        if (this.props.emoneyaccount) {
            if (this.props.userdata.emoneyAccount) {
                this.setState({ isLoadingIner: true }, () => {
                    apicall({ emoney_id: this.props.emoneyaccount.emoney_id, TFac_token_session: this.props.emoneyaccount.TFac_token_session }, 'tranchehistory')
                        .then((data) => {
                            this.setState({ isLoadingIner: false })
                            if (data.info == "success") {
                                this.setState({data:data.data})
                            } else {
                                if (data.message === "INVALID_TFac_token_session" || data.message ==="EXPIRED_SESSION_TFAC") {
                                    this.props.dispatch({ type: "OPEN_MODAL" })
                                } else {
                                    toast.error(data.message, {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }

                            }
                        })
                })
            } else {
                this.props.dispatch({ type: "OPEN_MODAL" })
            }
        } else {
            this.props.dispatch({ type: "OPEN_MODAL" })
        }
    }
   
    render() {
        if (this.state.isLoadingIner) {
            return (
                <div className="row p-2">
                    <div className="col-md-6">
                        <CatalogMagic />
                    </div>
                    <div className="col-md-6">
                        <CatalogMagic />
                    </div>
                </div>
            )
        }else{
            return (
                <div className='pt-4'>
                    <MyHelmet title={"Paiement par tranche - " + Constant.APP_DESC} description={Constant.APP_DESC} />
                    {this.state.data.map((item,index)=>{
                        return(
                            <Link to={`/portefeuille/paiement-par-tranche/${item.id}`} key={item.id} className="d-flex justify-content-between align-items-center bg-white br-5 py-2 px-1 mb-2 myshadow">
                                <div className="d-flex align-items-center">
                                    <div className="prevImgTranche" style={{ backgroundImage: `url(${fullcart})` }}></div>
                                    <div className="font-weight-bold ml-1">
                                        <div>Acompte N°{item.id}</div>
                                        <div className="text-warning">{numberFormat(item.total_a_payer)} CFA</div>
                                        <div className="text-primary details-s">Date cmd.: <span className="text-danger">{item.date_commande}</span></div>
                                        <div className="text-primary details-s">Reste A payé: <span className="text-danger">{numberFormat(item.totalrestant)} CFA</span></div>
                                    </div>
                                </div>
                                <span className={`badge badge-` + (item.acompterestant === 0 ? "success" :"warning")+` text-white`}>{item.acompterestant === 0 ? "Solder" :"En cours"}</span>
                            </Link>
                        )
                    })}
                </div>

            )
        }
        
    }
}

const mapStateToProps = state => ({ userdata: state.toggleSession.userdata, emoneyaccount: state.toggleSession.emoneyaccount, showModal: state.toggleSession.showModal })
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PaiementParTranche);