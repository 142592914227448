
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import Helmet from "../components/Helmet"
import Article from "../components/Article"
import CatalogMagic from '../components/ShimmerLoader'
import apicall, { getapicall } from "../API/APICALL"
import { Link } from "react-router-dom";
import emptyProduct from "../assets/empty_product.svg"
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
class Catalogue extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            isLoading:false,
            data: [],
            current_page: 1,
            totalpage: 0,
            loadingNext:false
        }
        this.prevSearchVAlue = this.props.history.location.state.query
    }
    componentDidMount(){
        this.getmesproduits()
    }
    componentDidUpdate(){
        console.log(this.prevSearchVAlue)
        if (this.prevSearchVAlue !== this.props.history.location.state.query){
            this.prevSearchVAlue = this.props.history.location.state.query
            this.getmesproduits()
        }
    }
    getmesproduits = () => {
        this.setState({ isLoading: true }, () => {
            getapicall(`https://ventepro.online/users/function/API/searchproduct.php?page=1&k=${encodeURIComponent(this.props.history.location.state.query)}`).then(data => {
                this.setState({ isLoading: false })
                if (data.info == "success") {
                    this.setState({
                        data: data.data,
                        curentPage: data.page, 
                        totalpage: data.totalpage
                    }, () => {
                        window.scrollTo(0, 0)
                    })
                } else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
        })
    }
   
    __renderdata = () => {
        if (this.state.isLoading) {
            return (
                <div className="row">
                    <div className="col-md-6">
                        <CatalogMagic />
                    </div>
                    <div className="col-md-6">
                        <CatalogMagic />
                    </div>
                </div>
            )
        } else {
            if (this.state.data.length > 0) {
                return (
                    <div className="row no-gutters">
                        {this.state.data.map((item, index) => {
                            return (
                                <div className="col-6 col-md-4 col-lg-3 px-1 mb-1" key={index}>
                                    <Article item={item} />
                                </div>
                            )
                        })}

                    </div>
                )

            }else{
                return(
                    <div className="text-center">
                        <img src={emptyProduct} className="img-fluid" style={{maxWidth:"200px"}}/>
                    </div>
                )
            }
        }
    }
    
    loadNext = () => {
        if (this.state.curentPage <= this.state.totalpage) {
            this.state.curentPage++
            this.setState({ loadingNext: true }, () => {
                getapicall(`https://ventepro.online/users/function/API/searchproduct.php?page=${this.state.curentPage}&k=${encodeURIComponent(this.props.history.location.state.query)}`).then(data => {
                    this.setState({ loadingNext: false, data: [...this.state.data, ...data.data] })
                })
            })
        }
    }
    render() {
        return (
            <div className="container pt-4 pb-4">
                <Helmet title={this.props.history.location.state.query} description={"Resultat des rechercher pour " + this.props.history.location.state.query}/>
                <h5 className="text-center text-info text-bold">Résultat des recherches</h5>
                <h2 className="text-center" style={{ color: 'black', fontWeight: 900 }}> {this.props.history.location.state.query}</h2>
                {this.__renderdata()}
                {this.state.curentPage <= this.state.totalpage &&
                    <div className="text-center mt-3 ">
                        <button className="btn btn-primary" disabled={this.state.loadingNext} onClick={() => { this.loadNext() }}>
                            {this.state.loadingNext ? <Icofont icon="spinner" spin="true" /> : "Charger plus"}
                        </button>
                    </div>
                }
                <ToastContainer/>
            </div>

        )
    }
}

const mapStateToProps = state => ({ cartlist: state.toggleCart.cartList })
const mapDispatchToProps = (dispatch) => {
    return {
        addtocart: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Catalogue);