
import React from 'react'
import Icofont from 'react-icofont';
import Constant from "../constants/constant"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import numberFormat from '../function/numberFormat'
import Helmet from "../components/Helmet"
import { ToastContainer, toast } from 'react-toastify';
import apicall from "../API/APICALL"
import Swal from "sweetalert2"
 class Cart extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            pricetotal:0,
            options:false,
            actionText:"CONTINUER",
            paytype:null,
            acompte:"",
            step:1,
            isPaying:false,
            WheelBonus:[],
            isLoadingWheelBonus:true,
            applyedRemiseItem:[],
            displaySideBar:false,
            selectedItem:null
        }
        this.total=0
    }
    increment=(item)=>{
        this.total = 0
        const action = { type: "INCREMENT_QUANTITE", value: item }
        this.props.dispatchAction(action)
    }
     decrement = (item) => {
         this.total = 0
         const action = { type: "DECREMENT_QUANTITE", value: item }
         this.props.dispatchAction(action)
     }
     remove = (item) => {
         this.total = 0
         const response=window.confirm("Confirmez votre acction")
         if (response){
             const action = { type: "REMOVE_FROM_CART", value: item }
            this.props.dispatchAction(action)
        }
     }
    componentDidUpdate(){
        this.total=0
    }
    componentDidMount(){
        this.getSolde()
        this.total=0
    }
    pay=()=>{
        if (this.props.userdata){
            if (this.props.userdata.emoneyAccount){
                if(this.state.applyedRemiseItem.length>0){
                    this.setState({
                        paytype:'direct',
                        actionText: "CONTINUER",
                        options: true
                    })
                }else{
                    this.setState({
                        paytype:null,
                        actionText: "CONTINUER",
                        options: true
                    })
                }
            }else{
                this.props.history.push('/portefeuille?callback=panier')
            }
        }else{
            this.props.history.push('/se-connecter?callback=panier')
        }
        
    }
    getReservedwheelBonus=()=>{
        apicall({ emoney_id: this.props.emoneyaccount.emoney_id, TFac_token_session: this.props.emoneyaccount.TFac_token_session }, 'getreservedwheelgame')
            .then(data => {
                this.setState({ isLoadingWheelBonus: false })
                if (data.info == "success") {
                    this.setState({
                        WheelBonus: data.data
                    })
                } else {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
    }
     togglePayType = (paytype)=>{
        this.setState({
            paytype:paytype,
            actionText: paytype=="direct"?"VALIDER":"CONTINUER"
        })
    }
    resetPay=()=>{
        this.setState({ 
            options: false,
            actionText: "CONTINUER",
            paytype: null,
            acompte: "",
            step: 1
         })
    }
    triggerAction=()=>{
        if (this.state.paytype){
            if (this.state.paytype==="direct"){
                this.payercash()
            }else{
                if (typeof this.state.acompte == "number" && this.state.acompte>1){
                    this.setState({step:2})
                }else{
                    toast.error("Veuillez saisir une tranche valide", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        }else{
            toast.error("Veuillez séléctionner un type de paiement", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
     payercash = () => {
         var produits = []
         var totala = 0
         this.props.cartlist.map((item) => {
             let thisproduit = {
                 typevente: item.typevente,
                 id_produit: item.id,
                 TFac_token_session: this.props.emoneyaccount.TFac_token_session,
                 emoney_id: this.props.emoneyaccount.emoney_id,
                 amout: item.prixProm,
                 qte_produit: item.quantite
             }
             let findItem = this.state.applyedRemiseItem.findIndex((applyedItem) => {
                 return applyedItem.itemId == item.id
             })
             let prix
             if (findItem != -1) {
                 thisproduit.withbonus = true
                 thisproduit.bonuspercentage = this.state.applyedRemiseItem[findItem].bonuspercentage
                 thisproduit.codepromo = this.state.applyedRemiseItem[findItem].codepromo
                 prix = Math.ceil((item.prixProm - (item.prixProm * (this.state.applyedRemiseItem[findItem].bonuspercentage / 100))) * item.quantite)
             } else {
                 prix = (item.prixProm * item.quantite)
             }
             totala = totala + prix
             produits.push(thisproduit)
             
         })
         if (this.props.userdata.emoneySolde > totala) {
                if (window.confirm("Confirmez le paiement")) {
                    this.setState({ isPaying: true }, () => {
                        apicall(produits, 'acheter')
                        .then((data) => {
                            this.setState({ options: false, isPaying: false }, () => {
                                if (data.info == "success") {
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Félicitation',
                                        text: 'Paiement effectué appelez le +229 67512323 pour confirmation'
                                    })
                                    this.props.dispatchAction({ type: "EMPTY_CART" })
                                    this.props.history.push('/portefeuille')
                                } else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oups',
                                        text: data.message
                                    })
                                }
                            })
                        })
                    })
                }
            } else {
             this.setState({ options: false, isPaying: false }, () => {
                 Swal.fire({
                     title: 'Fonds insuffisant',
                     text: "Voulez-vous recharger votre compte?",
                     icon: 'warning',
                     showCancelButton: true,
                     confirmButtonColor: '#3085d6',
                     cancelButtonColor: '#d33',
                     confirmButtonText: 'OUI',
                     cancelButtonText: "NON"
                 }).then((result) => {
                     if (result.isConfirmed) {
                         this.props.history.push('/portefeuille/recharge')
                     }
                 })
             })
         }
     }
    payerpartranche=()=>{
        var obj = { acompte: this.state.acompte, produits: [] }
        var totala = 0
        this.props.cartlist.map((item) => {
            obj.produits.push({
                typevente: item.typevente,
                id_produit: item.id,
                TFac_token_session: this.props.emoneyaccount.TFac_token_session,
                emoney_id: this.props.emoneyaccount.emoney_id,
                amout: item.prixProm,
                qte_produit: item.quantite,
                imagelink: item.img1,
                title: item.nomProd,
                description: item.descr
            })
            totala = totala + (item.prixProm * item.quantite)
        })
        if (this.props.userdata.emoneySolde > Math.floor(totala / this.state.acompte)){
            this.setState({ isPaying: true }, () => {
                
                apicall(obj, 'acheterentranche')
                    .then((data) => {
                        this.setState({ options: false,isPaying:false }, () => {
                            if (data.info == "success") {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Félicitation',
                                    text: 'Paiement effectué'
                                })
                                this.props.dispatchAction({ type: "EMPTY_CART" })
                                this.props.history.push('/portefeuille')
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oups',
                                    text: data.message
                                })
                            }
                        })

                    })
            })
        }else{
            this.setState({ options: false, isPaying: false }, () => {
                Swal.fire({
                    title: 'Fond insuffisant',
                    text: "Voulez-vous rechargez votre compte?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OUI',
                    cancelButtonText:"NON"
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push('/portefeuille')
                    }
                })
            })
        }
    }
     getSolde = () => {
        if (this.props.emoneyaccount) {
            if (this.props.userdata.emoneyAccount) {
                apicall({ emoney_id: this.props.emoneyaccount.emoney_id, TFac_token_session: this.props.emoneyaccount.TFac_token_session }, 'getsolde')
                .then((data) => {
                    if (data.info == "success") {
                        this.props.dispatchAction({ type: "UPDATE_SOLDE", value: data.nonformatedsolde })
                        this.getReservedwheelBonus()
                    } else {
                        this.setState({ options: false },()=>{
                            let timerInterval
                            Swal.fire({
                                title: 'Important',
                                html: 'Entrez votre pin avant de pouruivre',
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: () => {
                                    Swal.showLoading()
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getContent()
                                        if (content) {
                                            const b = content.querySelector('b')
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft()
                                            }
                                        }
                                    }, 100)
                                },
                                willClose: () => {
                                    clearInterval(timerInterval)
                                }
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    this.props.history.push('/portefeuille?callback=panier')
                                }
                            })
                        })
                    }
                })
            } else {
                    this.props.history.push('/portefeuille?callback=panier')
            }
        }else {
            this.props.history.push('/portefeuille?callback=panier')
        }
     }
     updateacompte = (e) => {
         if (parseInt(e.target.value)) {
             this.setState({ acompte: parseInt(e.target.value) })
         } else {
             if (e.target.value == 0 || e.target.value == "") {
                 this.setState({ acompte: e.target.value })
             }
         }
     }

    renderApplyBtn=(item)=>{
        let findItem = this.state.applyedRemiseItem.findIndex((applyedItem) => {
            return applyedItem.itemId == item.id
        })
        
        if (findItem==-1 && this.state.WheelBonus.length>0){
            return (
                <div className="mt-2"><button className="btn btn-info btn-sm" onClick={() => { this.openSideBar(item) }}>Appliquer une remise</button></div>
            )
        } else if (findItem!=-1){
            return <div className="badge badge-danger">Remise de {this.state.applyedRemiseItem[findItem].bonuspercentage}% appliquée</div>
        }
    }
    openSideBar = (item)=>{
        this.setState({
            selectedItem:item,
            displaySideBar:true
        })
    }
    closeSideBar=()=>{
        this.setState({
            selectedItem: null,
            displaySideBar: false
        })
    }
    applyRemise=(remise)=>{
        let addRemise={
            itemId: this.state.selectedItem.id,
            codepromo: remise.bonusCode,
            bonuspercentage: remise.bonusPercentage,   
        }
        let tempArray = this.state.applyedRemiseItem
        tempArray.push(addRemise)
        this.setState({
            applyedRemiseItem: tempArray,
            WheelBonus:this.state.WheelBonus.filter(item=>item!=remise),
            displaySideBar:false
        })
    }
    renderSideBar=()=>{
        return(
            <div className="wheelcontainer" style={{ left: this.state.displaySideBar ? "0" : "-100vw" }} >
                <div className="wheelBonusCloser" onClick={this.closeSideBar} style={{ left: this.state.displaySideBar ? "0" : "-100vw" }}/>
                <div className="wheelBonusContainer myshadow" style={{ right: this.state.displaySideBar ? "0" : "-100vw" }}>
                    <div className="bg-info p-3 mb-2 text-white">Choisissez la remise à appliquer </div>
                    <div className="container h-100">
                        {
                            this.state.isLoadingWheelBonus &&
                            <div className="text-center text-danger d-flex h-100 align-items-center justify-content-center flex-column">
                                <Icofont icon="spinner" spin="true" size={2} />
                                <span className="mt-2">Chargement</span>
                            </div>
                        }
                        {
                            !this.state.isLoadingWheelBonus &&
                            this.state.WheelBonus.length===0 &&
                            <div className="text-center text-danger d-flex h-100 align-items-center justify-content-center flex-column">
                                <Icofont icon="trash" size="5" />
                                <span className="mt-2">Aucune remise</span>
                            </div>
                        }
                        {
                            !this.state.isLoadingWheelBonus &&
                            this.state.WheelBonus.map((item)=>{
                                let findItem = this.state.applyedRemiseItem.findIndex((applyedItem)=>{
                                    return applyedItem.codepromo == item.bonusCode
                                })
                                if (findItem==-1){
                                    return (
                                        <div key={item.bonusPercentage} className="row mb-1 bg-black text-white p-1">
                                            <div className="col-7">
                                                <div>{item.bonusPercentage}% de remise</div>
                                                <div className="mt-1 text-info">{item.bonusCode}</div>
                                                <small>Expire le : {item.bonusenddate}</small>
                                            </div>
                                            <div className="col-5 text-right justify-content-center align-items-center d-flex">
                                                <button className="btn btn-warning btn-sm text-white" onClick={() => { this.applyRemise(item) }}>Appliquer</button>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
    render() {
        if (this.props.cartlist.length == 0){
            return (
                <div className="container pt-5 text-center">
                    <span className="text-danger"><Icofont icon="trash" size="8" /></span>
                    <h5 className="mt-2">Votre panier est vide.</h5>
                </div>
            )
        }else{
            return (
                <div className="container">
                    <Helmet title={"Panier"} description={"Liste des produits disponible dans votre panier" + Constant.APP_NAME} />
                    <div className="row">
                        <div className="col-lg-9">
                            <section id="cart">
                                {this.props.cartlist.map((item, index) => {
                                    let findItem = this.state.applyedRemiseItem.findIndex((applyedItem) => {
                                        return applyedItem.itemId == item.id
                                    })
                                    let prixAfficher
                                    if (findItem!=-1){
                                        prixAfficher = Math.ceil((item.prixProm - (item.prixProm * (this.state.applyedRemiseItem[findItem].bonuspercentage / 100))) * item.quantite)
                                    }else{
                                        prixAfficher = (item.prixProm * item.quantite)
                                    }
                                    this.total = this.total + prixAfficher
                                    return (
                                        <div className="row p-1 no-gutters bg-white br-5 myshadow mb-2" key={index}>
                                                <div className="col-3">
                                                    <img src={item.img1} alt="" className="img-fluid" />
                                                </div>
                                                <div className="col-9 px-1">
                                                    <div className="row no-gutters pt-1">
                                                        <div className="col-9 ">
                                                            <span className="nomCart">{item.nomProd}</span>
                                                        </div>
                                                        <div className="col-3 text-right">
                                                            <div title="Supprimez du panier" className="text-danger remove" onClick={() => { this.remove(item) }}><Icofont icon={"close"} size="2"/></div>
                                                        </div>
                                                    </div>
                                                    <div className="descCart">
                                                        {item.descr}
                                                    </div>
                                                    <div className="priceCart my-1 font-weight-bold">
                                                        {numberFormat(prixAfficher)} CFA
                                                    </div>
                                                    <div>
                                                        <button className="btn btn-default btnQte cartbtn mr-3" style={{ background: "#d32f2f" }} type="button" id="button-minus" onClick={() => { this.decrement(item) }}> − </button>
                                                        <button className="btn btn-default btnQte cartbtn " style={{ background: "darkgray",color:"black" }} > {item.quantite}</button>
                                                        <button className="btn btn-default btnQte cartbtn ml-3" style={{ background: "#536dfe" }} type="button" id="button-plus" onClick={() => { this.increment(item) }}> + </button>
                                                    </div>
                                                    {this.renderApplyBtn(item)}
                                                </div>
                                            </div>
                                    )
                                })}
                            </section>
                        </div>
                        <div className="col-lg-3 mt-2 mt-lg-0 mb-2" >
                            <div onClick={this.pay} className="text-center text-white py-3 my-shadow" style={{ background: "rgb(83, 109, 254)",cursor:"pointer" }}>
                                <h5 className="mb-2 "><span>{numberFormat(this.total)}</span> FCFA</h5>
                                <div>
                                    CONFIRMER LE PAIEMENT
                            </div>
                            </div>

                            
                        </div>
                    </div>
                    {this.state.options && <div className="ContinueBar">
                        <div className="ClickZone" onClick={() => { this.resetPay()}}>

                        </div>
                        {this.state.step===1 && 
                        <div className="bg-white px-2 py-2 w-100" >
                            <h5 className="text-warning font-weight-bold text-center"> MODE DE PAIEMENT</h5>
                            <div className="divider mb-2"/>
                            <h6 className="text-warning text-center font-weight-bold">Montant à payer : {numberFormat(this.total)} CFA</h6>
                            <div className="shareCont2 py-2 my-2" hidden={this.state.applyedRemiseItem.length>0}>
                                <div className="form-check">
                                    <label className="form-check-label" onClick={()=>{this.togglePayType("acompte")}}>
                                        <input type="radio" className="form-check-input" name="optradio" defaultChecked={this.state.paytype === "acompte"} />
                                        Paiement en plusieus tranches (via votre portefeuille)
                                    </label>
                                </div>
                                {this.state.paytype ==="acompte" && 
                                    <div>
                                        <div className="descCart2 mb-1">
                                        Il s'agit d'un paiement étalé et sans frais. (Ex: Si le produit coûte 10.000f et que vous voulez payer en deux tranches vous aurez à payer 5000 x 2)
                                        </div>
                                    <input min={0} value={this.state.acompte} onChange={(e) => { this.updateacompte(e)}} placeholder="Entrez le nombre de tranche" type="number" className="w-100 px-2 mb-2" style={{ border: "2px gray solid" }} />
                                    </div>
                                }
                                
                                <div className="form-check">
                                    <label className="form-check-label" onClick={() => { this.togglePayType("direct") }}>
                                        <input type="radio" className="form-check-input" name="optradio" defaultChecked={this.state.paytype === "direct"} />
                                        Paiement en une fois (via votre portefeuille)
                                    </label>
                                </div>
                            </div>
                            <div className="shareCont text-danger text-center py-3 font-weight-bold">
                                Solde courant : {this.props.userdata.emoneySolde} CFA
                            </div>
                            <div className="mt-1 btn btn-default blackbtn mb-3" onClick={this.triggerAction} disabled={this.state.isPaying}>{this.state.actionText} {this.state.isPaying && <Icofont icon="spinner" spin="true" />}</div>
                            </div>}
                        {this.state.step==2 &&

                            <div className="bg-white px-2 py-2 w-100" >
                                <h5 className="text-warning font-weight-bold text-center"> DETAIL DU PAIEMENT</h5>
                                <div className="shareCont text-danger text-center py-3">
                                    <div className="mb-2"> <span className="step2Cont">Montant total :</span> {numberFormat(this.total)} CFA</div>
                                <div className="mb-2"> <span className="step2Cont">Montant du premier tranche (A PAYER) :</span> {numberFormat(Math.floor(this.total / this.state.acompte))} CFA</div>
                                    <div className="mb-2"> <span className="step2Cont">Nombre total de tranche :</span> {this.state.acompte}</div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-6">
                                    <button disabled={this.state.isPaying} className="btn btn-secondary btn-block w-100 " onClick={()=>{this.setState({step:1})}}>
                                            RETOUR
                                        </button>
                                    </div>
                                    <div className="col-6">
                                    <button className="btn btn-info btn-block w-100" onClick={this.payerpartranche} disabled={this.state.isPaying}>
                                        VALIDER {this.state.isPaying && <Icofont icon="spinner" spin="true" />}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        
                    </div>}
                    <ToastContainer/>
                    {this.renderSideBar()}
                </div>

            )
        }
            
        
    }
}
const mapStateToProps = state => ({ cartlist: state.toggleCart.cartList, userdata: state.toggleSession.userdata, emoneyaccount: state.toggleSession.emoneyaccount, showModal: state.toggleSession.showModal })
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchAction: (action) => { dispatch(action) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);