
import React from 'react'
import { Link } from "react-router-dom";
import Icofont from 'react-icofont';
import { connect } from "react-redux";
import Countdown from 'react-countdown';
import numberFormat from "../function/numberFormat"
class Article extends React.PureComponent{
    
    constructor(props){
        super(props)
        this.state = {
            hidden: false
        }

    }
    _toggleFavorite(item) {
        const action = { type: "TOGGLE_FAVORITE", value: item }
        this.props.togglelike(action)
    }
    calculatetimeleft=(time)=>{

    }
    renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Expirer!</span>
        } else {
            // Render a countdown
            let timer=''
            if(days>0){
                timer = days+" Jr"+( days > 1 ? "s" : "")
            }
            if (hours > 0) {
                timer = timer+" "+hours +"h" 
            }
            if (minutes > 0) {
                timer = timer + " " +minutes+ "min"
            }
            return (<span>
                {timer} {seconds} Sec
            </span>);
        }
    }
    render(){
        const {item}=this.props
        var inFavorite = this.props.favoriteAnnonces.findIndex((i) => { return i.id === item.id })
        
        var color = "gray"
        if (inFavorite !== -1) {
            color = "red"
        }
        return(
            <div className="article br-5" disabled={this.state.hidden}>
                <Link className="" to={"/produit/" + item.id + "/type/" + item.typevente}>
                    <div className="articleimg" style={{ backgroundImage: `url("${item.img1}")` }} >
                        <div className="counterCont myshadow">
                            <Countdown
                            date={item.dateFin}
                            renderer={this.renderer}
                            onComplete={() => { this.setState({ hidden: true })}}
                        /></div>
                    </div>
                    <div className="articleseparator mt-2"></div>
                    <div className="articlename">
                        {item.nomProd}
                    </div>
                    <div className="articleprice">
                        {numberFormat(item.prixProm)} FCFA
                    </div>
                </Link>
                <div className="qterestant d-flex justify-content-space-between align-items-center">
                    Qté. Reste : {item.qte}
                    <Icofont icon={"heart"} onClick={() => { this._toggleFavorite(item) }} style={{ color: color}}/>
                </div>
                <div className="menusous mb-2" hidden={this.props.expp}>
                    <Link className="btn btn-warning btn-sm text-center p-2 text-white jachetebtn" title="J'achète" to={"/produit/" + item.id + "/type/" + item.typevente}>
                    
                        <Icofont icon={"shopping-cart"} /> J'achète
                    </Link>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({ favoriteAnnonces: state.toggleFavorite.favoriteAnnonces })
const mapDispatchToProps = (dispatch) => {
    return {
        togglelike: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Article);